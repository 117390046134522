import React, { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";

//components
import Loading from "./components/ui/Loading";

//providers
import { Context } from "./providers/AuthProvider";

import AppRoutes from "./routes";

//styles
import "./assets/css/global.css";
import "./assets/css/ui.css";

const App = () => {
  const { loading } = useContext(Context);
  if (loading) {
    return <Loading full={true} />;
  } else {
    return (
      <Router>
        <AppRoutes />
      </Router>
    );
  }
};

export default App;
