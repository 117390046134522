import { useState, useEffect } from "react";
import { getStorage } from "../../utils/Storage";
import JwtDecode from "jwt-decode";
import { clearStorages } from "../../utils/Storage";
import api from "../../service/api";

export default function AuthHook() {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  let token = getStorage("jwtToken");

  /* const calcInatividade = () => {
    var tempoDeEspera = 20 * 60 * 1000; //20 minutos
    var timeout = setTimeout(inativo, tempoDeEspera);

    function actividade() {
      clearTimeout(timeout);
      timeout = setTimeout(inativo, tempoDeEspera);
    }

    function inativo() {
      clearStorages();
      window.location = "/";
    }

    [
      "keyup",
      "touchmove" in window ? "touchmove" : "mousemove",
      "onwheel" in document.createElement("div")
        ? "wheel"
        : document.onmousewheel !== undefined
        ? "mousewheel"
        : "DOMMouseScroll",
    ].forEach(function (ev) {
      window.addEventListener(ev, actividade);
    });
  }; */

  const validaToken = () => {
    var timeout = null;
    function validate() {
      api
        .get("https://backpearson.inovyo.com/api/checktoken", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          timeout = setTimeout(validate, 60000);
        })
        .catch(() => {
          if (timeout !== null) {
            clearTimeout(timeout);
          }
          clearStorages();
          window.location = "/";
        });
    }
    validate();
  };

  useEffect(() => {
    const userToken = token !== "" ? JwtDecode(token) : null;
    if (userToken !== null) {
      setAuthenticated(true);
      setUser(userToken);
      //calcInatividade();
      validaToken();
    }
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  return {
    authenticated,
    user,
    token,
    loading,
  };
}
