import React from "react";
import { Popover } from "antd";

const NPS = (props) => {
  let { data: NPSJson, language } = props;
  const { detrator, neutro, promotor } = NPSJson;

  const npsStatus = {
    espanhol: {
      detrator: "DETRACTOR",
      neutro: "NEUTRAL",
      promotor: "PROMOTOR",
      respostas: "Respuestas",
    },
  }[language] || {
    detrator: "DETRATOR",
    neutro: "NEUTRO",
    promotor: "PROMOTOR",
    respostas: "Respostas",
  };

  const barBalance = (d, n, p) => {
    let newPercent = d;
    let percentMaior = "d";

    const excedente = somaPercents - 100;

    if (n > newPercent) {
      newPercent = n;
      percentMaior = "n";
    }

    if (p > newPercent) {
      newPercent = p;
      percentMaior = "p";
    }

    newPercent = newPercent - excedente;

    switch (percentMaior) {
      case "d":
        d = newPercent;
        break;
      case "n":
        n = newPercent;
        break;
      case "p":
        p = newPercent;
        break;
      default:
        p = newPercent;
    }

    return { d, n, p };
  };

  const total = detrator + neutro + promotor;

  const percentDetrator = (detrator / total) * 100;
  const percentNeutro = (neutro / total) * 100;
  const percentPromotor = (promotor / total) * 100;

  let percentDetratorFaixa = percentDetrator;
  let percentNeutroFaixa = percentNeutro;
  let percentPromotorFaixa = percentPromotor;

  const somaPercents =
    percentDetratorFaixa + percentNeutroFaixa + percentPromotorFaixa;

  //calcula um tamanho mínimo para não quebrar o gráfico
  if (somaPercents > 100) {
    const { d, n, p } = barBalance(
      percentDetratorFaixa,
      percentNeutroFaixa,
      percentPromotorFaixa
    );
    percentDetratorFaixa = d;
    percentNeutroFaixa = n;
    percentPromotorFaixa = p;
  }

  return (
    <>
      <div className="wrap1">
        <div className="piece1">
          <span>{percentDetrator.toFixed(1)}%</span>
          {npsStatus.detrator}
        </div>
        <div className="piece2">
          <span>{percentNeutro.toFixed(1)}%</span>
          {npsStatus.neutro}
        </div>
        <div className="piece3">
          <span>{percentPromotor.toFixed(1)}%</span>
          {npsStatus.promotor}
        </div>
      </div>
      <div className="wrap2">
        {detrator > 0 && (
          <Popover
          content={
            <p>
              {npsStatus.respostas}: {new Intl.NumberFormat('pt-BR').format(detrator)}
            </p>
          }
          title={`${npsStatus.detrator} NPS`}
        >
          <div className="color1" style={{ width: percentDetratorFaixa + "%" }}>
            {new Intl.NumberFormat('pt-BR').format(detrator)}
          </div>
        </Popover>
        )}

        {neutro > 0 && (
          <Popover
            content={
              <p>
                {npsStatus.respostas}: {new Intl.NumberFormat('pt-BR').format(neutro)}
              </p>
            }
            title={`${npsStatus.neutro} NPS`}
          >
            <div className="color2" style={{ width: percentNeutroFaixa + "%" }}>
              {new Intl.NumberFormat('pt-BR').format(neutro)}
            </div>
          </Popover>
        )}

        {promotor > 0 && (
          <Popover
            content={
              <p>
                {npsStatus.respostas}: {new Intl.NumberFormat('pt-BR').format(promotor)}
              </p>
            }
            title={`${npsStatus.promotor} NPS`}
          >
            <div className="color3" style={{ width: percentPromotorFaixa + "%" }}>
              {new Intl.NumberFormat('pt-BR').format(promotor)}
            </div>
          </Popover>
        )}
      </div>
    </>
  );
};

export default NPS;
