import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row } from "antd";
import { FaPlusCircle } from "react-icons/fa";

//styles
import "../../assets/css/dashboard/dashboard.css";

//components
import TabsMenu from "./Menu/TabsMenu";
import CardColorfull from "./Card/CardColorfull";
import Card from "./Card/Card";

//redux
import {
  setMenuOpen,
  response,
  tabActive,
  menuOpen,
} from "../../store/dashboard/dashboard.slice";
import {
  loading,
  bodyWidth,
  setBodyWidth,
} from "../../store/application/app.slice";
import { getDataDashboard } from "../../store/dashboard/dashboard.actions";

const DashPage = () => {
  const dispatch = useDispatch();
  const dataDash = useSelector(response);
  const loadingRedux = useSelector(loading);
  const widthBodyRedux = useSelector(bodyWidth);
  const tabActiveRedux = useSelector(tabActive);
  const menuOpenRedux = useSelector(menuOpen);

  useEffect(() => {
    dispatch(getDataDashboard());
    if (widthBodyRedux < 768) {
      dispatch(setMenuOpen(false));
    }
    // eslint-disable-next-line
  }, []);

  const CreateContent = ({ item }) => {
    return (
      <>
        {item.type && item.type === "card-colorfull" && (
          <CardColorfull data={item} />
        )}
        {item.type && item.type === "card" && <Card data={item} />}
      </>
    );
  };

  window.addEventListener("resize", () => {
    if (widthBodyRedux < 768) {
      dispatch(setMenuOpen(false));
    }
    dispatch(setBodyWidth(document.body.clientWidth));
  });

  return (
    <>
      {!loadingRedux && dataDash !== null && (
        <div
          id="dashboard-page"
          className={`${menuOpenRedux ? "active" : "inactive"}`}
        >
          <TabsMenu />
          <div className="content-dashboard-filter"></div>
          <div id="content-dashboard" className="content-dashboard">
            {dataDash.content &&
              dataDash.content.map((content, indexContent) => {
                return (
                  <div
                    id={"content-" + content.contentId}
                    key={"c" + indexContent}
                    style={{
                      display:
                        tabActiveRedux === content.contentId ? "block" : "none",
                    }}
                    className={`container-fluid content-dashboard-item`}
                  >
                    {content.rows &&
                      content.rows.map((row, indexRow) => {
                        return (
                          <Row
                            gutter={[16, 16]}
                            className="row"
                            key={"r" + indexRow}
                          >
                            {row.graphics &&
                              row.graphics.map((item, indexGrap) => {
                                var graphData = {
                                  ...item,
                                  indexContent,
                                  indexRow,
                                  indexGrap,
                                };
                                return (
                                  <CreateContent
                                    item={graphData}
                                    key={"cc" + indexGrap}
                                  />
                                );
                              })}
                          </Row>
                        );
                      })}
                  </div>
                );
              })}
            <div className="container-fluid">
              <div className="add-new-row">
                <Button type="primary" icon={<FaPlusCircle />}>
                  Nova Linha
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashPage;
