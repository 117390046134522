import React from "react";
import { Layout } from "antd";

const FooterApp = () => {
  const { Footer } = Layout;

  const showFooter = () => {
    const route = window.location.pathname;
    if (route.indexOf("/dashboard/") >= 0) {
      return true;
    }

    if (route === "/") {
      return true;
    }

    return false;
  };

  return (
    <>
      {showFooter() && (
        <Footer style={{ textAlign: "center" }}>
          © Inovyo | Experience Management. Todos os direitos reservados.
        </Footer>
      )}
    </>
  );
};

export default FooterApp;
