import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

const BarChart = (props) => {
  let { data: dataJson, design } = props;
  const [setting, setSetting] = useState(null);

  // Função para encurtar os rótulos
  const truncateLabel = (label) => {
    return label.length > 15 ? `${label.substring(0, 12)}...` : label;
  };

  const values = Object.values(dataJson.bar.data);
  const delta = Math.max(...values) - Math.min(...values)

  let settings = {
    default: {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            align: "start",
            clamp: true,
            anchor: "end",
            offset: -20,
            display: true,
            formatter: (value) => new Intl.NumberFormat("pt-BR").format(value)
          },
          tooltip: {
            callbacks: {
              title: function(tooltipItem) {
                const originalLabel = Object.keys(dataJson.bar.data)[tooltipItem[0]['dataIndex']];
                return `${originalLabel}`;
              },
              label: function(tooltipItem) {
                const originalLabel = Object.keys(dataJson.bar.data)[tooltipItem['dataIndex']];
                return `${new Intl.NumberFormat('pt-BR').format(dataJson.bar.data[originalLabel])}`;
              }
            }
          }
        },
        scales: {
          x: {
            grid: {
              lineWidth: 0,
            },
          },
          y: {
            display: false,
            max: Math.max(...values) + 0.2*delta
          },
        },
      },
      data: {
        datasets: [
          {
            maxBarThickness: 50,
            minBarLength: 10,
            borderColor: "#9A2376",
            backgroundColor: [
              "#9A2376",
              "#FFC400",
              "#008D3F",
              "#00A3DD",
              "#FF1928",
              "#8E0A14",
              "#593959",
              "#1BBF37",
              "#F64827",
              "#77429D",
              "#FFA300",
            ],
          },
        ],
      },
    },
    relationalBarYellow: {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            align: "start",
            clamp: true,
            anchor: "end",
            offset: -20,
            display: true,
          },
          legend: {
            position: "right",
          },
          title: {
            display: true,
            text: "Medidas",
            position: "right",
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                const originalLabel = dataJson.bar.data[tooltipItem.label];
                return `${tooltipItem.label}: ${originalLabel}`;
              }
            }
          }
        },
        scales: {
          x: {
            display: true,
            grid: {
              lineWidth: 0,
            },
          },
          y: {
            display: false,
            grid: {
              color: "#aaa",
              lineWidth: 1,
            },
            border: {
              dash: [2, 10],
            },
          },
        },
      },
      data: {
        datasets: [
          {
            type: "line",
            borderWidth: 2,
            fill: false,
            borderColor: "#9A2376",
            backgroundColor: "#9A2376",
            order: 0,
          },
          {
            type: "bar",
            maxBarThickness: 50,
            minBarLength: 10,
            borderColor: "#9A2376",
            backgroundColor: "#FFC400",
            order: 1,
          },
        ],
      },
    },
  };

  useEffect(() => {
    let setDesign = settings[design] ? settings[design] : settings["default"];

    // Injeção de dados padrão
    if (design === undefined || design === null) {
      setDesign.data.labels = Object.keys(dataJson.bar.data).map(truncateLabel);
      setDesign.data.datasets[0].data = Object.values(dataJson.bar.data);
    } else if (design === "relationalBarYellow") {
      // Injeção de dados gráfico relacional amarelo
      setDesign.data.labels = Object.keys(dataJson.bar.data).map(truncateLabel);

      // Dados da barra
      setDesign.data.datasets[1].data = Object.values(dataJson.bar.data);
      setDesign.data.datasets[1].label = dataJson.bar.legend;

      // Dados da linha
      setDesign.data.datasets[0].data = Object.values(dataJson.line.data);
      setDesign.data.datasets[0].label = dataJson.line.legend;
    }

    setSetting(setDesign);
    // eslint-disable-next-line
  }, []);

  const screenHeight = window.innerHeight - 84;

  return (
    <>
      {setting !== null && (
        <Bar {...props} data={setting.data} options={setting.options} />
      )}
    </>
  );
};

export default BarChart;
