import React from "react";

import { Layout } from "antd";

//components
import HeaderApp from "./HeaderApp";
import ContentApp from "./ContentApp";
import FooterApp from "./FooterApp";
import Loading from "./Loading";

const LayoutApp = (props) => {
  const { children } = props;

  return (
    <Layout>
      <Loading />
      <HeaderApp />
      <ContentApp>{children}</ContentApp>
      <FooterApp />
    </Layout>
  );
};

export default LayoutApp;
