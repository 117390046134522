import React, { useRef, useState } from "react";
import { Input, Space, Table, Button, Card, Tooltip, Popconfirm } from "antd";
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

//utils
import { setPageTitle } from "../../utils/Strings";

//styles
import "../../assets/css/users/users.css";

const UsersPage = () => {
  setPageTitle(["Usuários"]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const deleteUser = () => {};

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Resetar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),

    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Criado em",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdAt > b.createdAt,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Empresa",
      dataIndex: "company",
      key: "company",
      sorter: (a, b) => a.company > b.company,
      ...getColumnSearchProps("company"),
    },
    {
      title: "Conta",
      dataIndex: "account",
      key: "account",
      width: 150,
      sorter: (a, b) => a.account - b.account,
      ...getColumnSearchProps("account"),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
      ...getColumnSearchProps("name"),
    },
    {
      title: "E-mail ",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email - b.email,
      ...getColumnSearchProps("email"),
    },
    {
      title: "Regra",
      dataIndex: "rule",
      key: "rule",
      width: 100,
      filters: [
        {
          text: "Administrador",
          value: "admin",
        },
        {
          text: "Customer Success",
          value: "customerSuccess",
        },
        {
          text: "Comercial",
          value: "sale",
        },
        {
          text: "Cliente",
          value: "client",
        },
        {
          text: "Demo",
          value: "demo",
        },
        {
          text: "FTP",
          value: "ftp",
        },
      ],
      onFilter: (value, record) => record.rule.indexOf(value) === 0,
    },
    {
      title: "Ação",
      key: "action",
      width: 70,
      align: "center",
      render: (row) => {
        return (
          <Tooltip title="Excluir usuário">
            <Popconfirm
              title={`Excluir o usuário ${row.email}?`}
              okText="Sim"
              cancelText="Não"
              placement="bottomRight"
              onConfirm={() => deleteUser(row.id)}
            >
              <Button
                shape="circle"
                value="small"
                icon={<DeleteFilled style={{ color: "#9a2376" }} />}
              ></Button>
            </Popconfirm>
          </Tooltip>
        );
      },
    },
  ];

  // Componente: Tabela de usuários
  let data = [
    {
      key: 1,
      createdAt: "21/03/2023",
      company: "Marvel - Shield",
      account: `Shield`,
      name: "Nick Fury",
      email: "nick.fury@shield.com",
      rule: "admin",
    },
  ];

  return (
    <div id="users-page">
      <Card title="Usuários">
        <div className="float-button">
          <Button type="primary" size="small" href="/users/create">
            Novo Usuário
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: 10,
          }}
          scroll={{
            y: 480,
          }}
        />
      </Card>
    </div>
  );
};

export default UsersPage;
