import { handleLoginAPI, handleLoginLightAPI } from "./auth.service";
import { setLoading } from "../application/app.slice";
import { message } from "antd";
import { setStorage } from "../../utils/Storage";
import i18n from "../../utils/i18n/pt-BR";

/**
 * Realiza login na API da aplicação
 * @param {Object} credentials
 * @param {string} credentials.email
 * @param {string} credentials.password
 */
export const handleLogin = (credentials) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    handleLoginAPI(credentials)
      .then((data) => {
        const { jwtToken } = data;
        if (jwtToken) {
          dispatch(setLoading(false));
          setStorage("jwtToken", jwtToken);
          message.success({
            content: i18n.APP_ACCESS_APRROVED,
            key: "login",
          });
          setTimeout(() => {
            window.location = "/home";
          }, 2000);
        } else {
          dispatch(setLoading(false));
          message.error({
            content: "ATENÇÃO!",
            key: "login",
          });
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        message.error({
          content: i18n.GENERIC_ERROR,
          key: "login",
        });
      });
  };
};

export const handleLoginLight = (credentials) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    handleLoginLightAPI(credentials)
      .then((data) => {
        const { status, token } = data;
        if (status) {
          dispatch(setLoading(false));
          setStorage("jwtToken", token);
          message.success({
            content: i18n.APP_ACCESS_APRROVED,
            key: "login",
          });
          setTimeout(() => {
            window.location = "/home";
          }, 2000);
        } else {
          dispatch(setLoading(false));
          message.error({
            content: "ATENÇÃO!",
            key: "login",
          });
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        message.error({
          content: i18n.GENERIC_ERROR,
          key: "login",
        });
      });
  };
};
