import { configureStore } from "@reduxjs/toolkit";

import app from "./application/app.slice";
import dashboard from "./dashboard/dashboard.slice";
import auth from "./auth/auth.slice";
import integration from './integration/integration.slice'

export default configureStore({
  reducer: {
    app,
    dashboard,
    auth,
    integration
  },
});
