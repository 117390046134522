import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import { Input, Modal, Col, Card, Select } from "antd";
import { useDispatch } from "react-redux";

//styles
import "../../../assets/css/dashboard/card/card-colorfull.css";

//redux
import { setResponse, edition } from "../../../store/dashboard/dashboard.slice";
import { bodyWidth } from "../../../store/application/app.slice";

//utils
import { setStorage, getStorage } from "../../../utils/Storage";

const CardColorfull = (props) => {
  const { Option } = Select;

  let {
    leftColor,
    rightColor,
    icon,
    title,
    textColor,
    quantity,
    width,
    indexContent,
    indexRow,
    indexGrap,
  } = props.data;

  width = width || 12;
  icon = icon || "";

  const dispatch = useDispatch();
  const editionRedux = useSelector(edition);
  const bodyWidthRedux = useSelector(bodyWidth);
  const [modalOpen, setModalOpen] = useState(false);
  const [leftColorSeleted, setLeftColor] = useState(leftColor);
  const [rightColorSeleted, setRightColor] = useState(rightColor);
  const [textColorSelected, setTextColor] = useState(textColor);
  const [titleSelected, setTitle] = useState(title);
  const [widthSelected, setWidth] = useState(width);

  let myStyles = {
    background: `linear-gradient(90deg, ${leftColor} 0%, ${rightColor} 100%)`,
    borderRadius: "0.4rem",
    //margin: "0 0.5rem",
  };

  const update = () => {
    let newDash = JSON.parse(getStorage("DASH"));
    let path = newDash.content[indexContent].rows[indexRow].graphics[indexGrap];
    path = {
      ...path,
      leftColor: leftColorSeleted,
      rightColor: rightColorSeleted,
      textColor: textColorSelected,
      title: titleSelected,
      width: widthSelected,
    };
    newDash.content[indexContent].rows[indexRow].graphics[indexGrap] = path;
    dispatch(setResponse(newDash));
    setStorage("DASH", JSON.stringify(newDash));
  };

  const optionItems = [...Array(24)].map((x, i) => (
    <Option key={"select" + x + i} value={1 + i} />
  ));

  return (
    <Col span={bodyWidthRedux < 768 ? 24 : width}>
      <Card
        id="card-color"
        style={myStyles}
        styles={{
          body: { padding: "0px" }
        }}
      >
        {editionRedux && (
          <>
            <div
              className="edit-colorfull-card"
              onClick={() => setModalOpen(!modalOpen)}
            >
              <FaRegEdit />
            </div>
            <Modal
              title="Editar"
              visible={modalOpen}
              okText="Salvar"
              styles={{
                body: { padding: "0px" }
              }}
              onOk={() => {
                setModalOpen(!modalOpen);
                update();
              }}
              onCancel={() => setModalOpen(!modalOpen)}
            >
              <div id="modal-card-colorfull">
                <h6>Cor do Gradiente Esquerdo</h6>
                <div className="color">
                  <input
                    type="color"
                    id="leftColor"
                    name="leftColor"
                    value={leftColorSeleted}
                    onChange={(e) => setLeftColor(e.target.value)}
                  />
                </div>
                <h6>Cor do Gradiente Direito</h6>
                <div className="color">
                  <input
                    type="color"
                    id="rightColor"
                    name="rightColor"
                    value={rightColorSeleted}
                    onChange={(e) => setRightColor(e.target.value)}
                  />
                </div>
                <h6>Cor do Texto</h6>
                <div className="color">
                  <input
                    type="color"
                    id="setTextColor"
                    name="setTextColor"
                    value={textColorSelected}
                    onChange={(e) => setTextColor(e.target.value)}
                  />
                </div>
                <h6>Título</h6>
                <div className="input">
                  <Input
                    value={titleSelected}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <h6>Largura</h6>
                <div className="input">
                  <Select
                    defaultValue={widthSelected}
                    onChange={(value) => setWidth(value)}
                    style={{ width: "100%" }}
                  >
                    {optionItems}
                  </Select>
                </div>
              </div>
            </Modal>
          </>
        )}
        <span style={{ color: `${textColor}` }}>{title}</span>
        <h1 style={{ color: `${textColor}` }}>{quantity}</h1>
        {icon && icon !== "" && <div className={`icon icon-${icon}`}></div>}
      </Card>
    </Col>
  );
};

export default CardColorfull;
