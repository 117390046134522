import React, { useState, useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FaCog } from "react-icons/fa";
import { IoExitOutline } from "react-icons/io5";

//import { Layout, Modal, Menu } from "antd";
import { Layout, Modal } from "antd";
//import { SettingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

//redux
import {
  menuOpen,
  setMenuOpen,
  edition,
  response,
} from "../../store/dashboard/dashboard.slice";

//utils
import { clearStorages } from "../../utils/Storage";

//styles
import "../../assets/css/app/header-app.css";

/**
 * Header da Aplicação
 */
const HeaderApp = () => {
  const { Header } = Layout;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false); //exibe ou não o header
  const [modalOpen, setModalOpen] = useState(false);
  const [inDashboard, setInDashboard] = useState(false); //quando o cliente está navegando no dashboard
  const [inDashboardLight, setInDashboardLight] = useState(false); //quando o cliente está navegando no dashboard light
  //const [selectedKey, setSelectedKey] = useState("");
  const menuOpenRedux = useSelector(menuOpen);
  const editionRedux = useSelector(edition);
  const dataDash = useSelector(response);

  /* const getMenuKeyByRoute = () => {
    const routeName = document.location.pathname;

    const listRoute =
      {
        "/home": "home",
        "/bases": "bases",
        "/users": "users",
        "/surveys": "surveys",
        "/settings": "settings",
      }[routeName] || "";

    setSelectedKey(listRoute);
  }; */

  useEffect(() => {
    setShow(showHeader());
    //getMenuKeyByRoute();
  }, []);

  /**
   * Valida se exibe ou não o Header da aplicação
   *
   * @param {string} route
   * @returns {bool}
   */
  const showHeader = () => {
    const route = window.location.pathname;

    if (route.indexOf("dashboardlight") >= 0) {
      setInDashboardLight(true);
      return true;
    }

    if (route.indexOf("dashboard") >= 0) {
      setInDashboard(true);
      return true;
    }

    if (route === "/") {
      return false;
    }

    return true;
  };

  const toggleMenu = () => {
    dispatch(setMenuOpen(!menuOpenRedux));
  };

  //Componentes do menu
  /* const items = [
    {
      label: <a href="/home">Dashboard</a>,
      key: "home",
    },
    {
      label: <a href="/bases">Bases</a>,
      key: "bases",
    },
    {
      label: <a href="/users">Usuários</a>,
      key: "users",
    },
    {
      label: <a href="/surveys">Pesquisas</a>,
      key: "surveys",
    },
    {
      label: "Conta",
      icon: <SettingOutlined />,
      key: "SubMenu",
      style: { position: "absolute", right: 24 },
      children: [
        {
          type: "group",
          children: [
            {
              label: <a href="/settings">Configurações</a>,
              key: "settings",
            },
            {
              label: <a href="/logout">Sair</a>,
              key: "logout",
            },
          ],
        },
      ],
    },
  ]; */

  return (
    <>
      {show &&
        inDashboard === false &&
        inDashboardLight === false && ( //Header padrão
          <>
            <Header
              id="header-app"
              theme="light"
              style={{ position: "fixed", zIndex: 1, width: "100%" }}
            >
              <a href="/">
                <div className="logo" />
              </a>
              <IoExitOutline
                alt="Botão Sair"
                className="logo-exit"
                title="Sair"
                onClick={() => {
                  clearStorages();
                  window.location = "/";
                }}
              />
            </Header>
          </>
        )}

      {inDashboard && ( //Header do dashboard
        <>
          <header id="header-dashboard">
            <div className="header-layout">
              <div className="menu" id="menu-main" onClick={() => toggleMenu()}>
                <AiOutlineMenu />
              </div>
              <div className="menu-details">
                {editionRedux && (
                  <div className="btn-settings">
                    <FaCog onClick={() => setModalOpen(!modalOpen)} />
                  </div>
                )}
                <div className="desc-dash">
                  <h6>{dataDash.clientName}</h6>
                  <span>{dataDash.name}</span>
                </div>
              </div>
            </div>
          </header>
          <Modal
            title="Configurações do Dashboard"
            open={modalOpen}
            okText="Salvar"
            styles={{
              body: { padding: "0px" }
            }}
            onOk={() => {
              setModalOpen(!modalOpen);
              //update();
            }}
            onCancel={() => setModalOpen(!modalOpen)}
          ></Modal>
        </>
      )}
    </>
  );
};

export default HeaderApp;
