import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";

//providers
import { Context } from "../providers/AuthProvider";

//components
import LayoutApp from "../components/app/LayoutApp";

//routes
import { listRoutes, activeRoute } from "./all";

function AppRoutes() {
  let match = window.location.pathname;
  const { loading, authenticated } = useContext(Context);
  const allRoutes = listRoutes();
  const { auth } = activeRoute();

  // primeiro momento que entra no app. "splashscreen"
  if (loading) {
    return <div id="splash-message">Carregando...</div>;
  } else {
    /* Quando autenticado e estiver na rota /login
     * será redirecionado para /home */
    if (authenticated && match === "/") {
      window.location = "/home";
      return;
    }

    /* Se auth existir vindo do componente de rota,
      significa que é uma rota privada e deve validar todos
      os acessos antes do usuário visualizar */
    if (auth) {
      /* Verifica se usuário está logado ou não na aplicação.
          caso não esteja será redirecionado para a tela de login */
      if (authenticated) {
        //Se for preciso validar o acesso do usuário
        /* if (permission) {
          /* Verifica se tem permissão para ver a tela, se não tiver
            será redirecionado para a home e notificado que não
            tem permissão de acesso. *
          if (!permissionAccess(permission, user.user_access)) {
            window.location = "/home?nopermission";
            return;
          }
        } */
      } else {
        window.location = "/";
        return;
      }
    }
  }

  return (
    <LayoutApp>
      <Routes>
        {allRoutes.map((e, index) => {
          return (
            <Route path={e.path} key={"route" + index} element={<e.page />} />
          );
        })}
      </Routes>
    </LayoutApp>
  );
}

export default AppRoutes;
