import React from "react";
import { Input, Button, Checkbox, Form, Select, Card, Breadcrumb } from "antd";

//utils
import { setPageTitle } from "../../utils/Strings";

//styles
import "../../assets/css/users/create.user.css";

const CreateUserPage = () => {
  setPageTitle(["Usuários", "Criar Usuário"]);

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const options = [
    {
      value: "admin",
      label: "Administrador",
    },
    {
      value: "customerSuccess",
      label: "Customer Success",
    },
    {
      value: "sale",
      label: "Comercial",
    },
    {
      value: "client",
      label: "Cliente",
    },
    {
      value: "demo",
      label: "Demo",
    },
    {
      value: "ftp",
      label: "FTP",
    },
  ];

  return (
    <div id="create-user-page">
      <div className="breadcrumb-slot">
        <Breadcrumb
          items={[
            {
              title: "Usuários",
              href: "/users",
            },
            {
              title: "Criar Usuário",
            },
          ]}
        />
      </div>

      <Card title="Criar Usuário">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Nome da conta"
            name="account"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome da conta",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Empresa"
            name="company"
            rules={[{ required: true, message: "Por favor, insira o empresa" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contato"
            name="contact"
            rules={[{ required: true, message: "Por favor, insira o contato" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, message: "Por favor, insira o email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Permissão"
            name="rule"
            rules={[
              { required: true, message: "Por favor, selecione a permissão" },
            ]}
          >
            <Select options={options} placeholder="Selecione uma permissão" />
          </Form.Item>

          <Form.Item name="clientManagement" valuePropName="checked">
            <Checkbox>Gerenciado pelo cliente</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              CRIAR
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CreateUserPage;
