const CONST = {
  //APPLICATION
  INPUT_EMAIL_EMPTY: "Email está vazio.",
  INPUT_EMAIL_MINMAX_SIZE: "Email deve conter entre 8 e 150 caracteres.",
  INPUT_EMAIL_INVALID: "Este email é inválido.",
  INPUT_PASSWORD_EMPTY: "Senha está vazia.",
  INPUT_PASSWORD_MINMAX_SIZE: "Senha deve conter entre 8 e 64 caracteres.",
  APP_ACCESS_APRROVED: "Agora você está logado. Aguarde...",
  VALIDATING_ACCESS: "Validando seu acesso...",
  GENERIC_ERROR: "Ocorreu um erro! tente novamente ou contate a equipe técnica.",
};

export default CONST;
