//PUBLIC ROUTES
import LoginPage from "../pages/Auth/LoginPage";
import NotFoundPage from "../pages/Home/NotFoundPage";

//PRIVATE ROUTES
import InitPage from "../pages/Home/InitPage";
import UsersPage from "../pages/Users/UsersPage";
import CreateUserPage from "../pages/Users/CreateUserPage";
import AccessPage from "../pages/Settings/Access/AccessPage";
import DashboardLightPage from "../pages/DashboardLight/DashboardLightPage";
import DashPage from "../pages/Dashboard/DashPage";

export const listRoutes = () => {
  return [
    //GENERAL
    { path: "/", page: LoginPage },
    { path: "/home", page: InitPage, auth: true },

    //USERS
    { path: "/users", page: UsersPage },
    { path: "/users/create", page: CreateUserPage },

    //DASHBOARDS
    {
      path: "/dashboardlight/:dashid",
      page: DashboardLightPage,
      name: "dashboardlight",
      id: "dahboard-light",
      auth: true,
    },
    {
      path: "/dashboard/:dashid",
      page: DashPage,
      name: "dashboard",
      id: "dahboard",
      auth: true,
    },

    //SETTINGS
    { path: "/settings/access", page: AccessPage, auth: true },

    //NO MATCH
    { path: "*", page: NotFoundPage },
  ];
};

export const activeRoute = () => {
  let route = window.location.pathname;
  let routeSelected = {};
  listRoutes().forEach((element) => {
    if (element.path === route || route.indexOf(element.name) >= 0) {
      routeSelected = element;
      return;
    }
  });
  return routeSelected;
};
