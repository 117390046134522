import React, { useState, useEffect, useContext } from "react";
import { Space, Button } from "antd";
import { Col, Row, Card, Select, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  FaFilter,
  FaQuestionCircle,
} from "react-icons/fa";
import {
  RiFileExcel2Fill,
  RiCloseCircleFill,
  RiFileDownloadFill,
} from "react-icons/ri";

import api from "../../../service/api";

//components
import MainMenu from "../Menu";
import { CardKPI, CardChart, CreateElement } from "../Card";

//data
//import dataJSON from "../data.json";

//redux
import {
  tabActive,
  menuOpen,
  setResponseLight,
  responseLight,
  setInfoModal,

  isModalVisible,
  fullScreenTitle,
  setIsModalVisible,
  setChartType,
  setChartData,
  setFullScreenTitle,
} from "../../../store/dashboard/dashboard.slice";

//providers
import { Context } from "../../../providers/AuthProvider";

const PearsonWizard2024 = () => {
  const { dashid } = useParams();
  let { token } = useContext(Context);

  const tab = useSelector(tabActive);
  const activeMenu = useSelector(menuOpen);
  const dataJSON = useSelector(responseLight);
  const dispatch = useDispatch();

  const modalVisible = useSelector(isModalVisible);
  const titleFullScreen = useSelector(fullScreenTitle);

  const [localLoad, setLocalLoad] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExcelOpen, setIsModalExcelOpen] = useState(false);
  const [hasFile, setHasFile] = useState("");

  const [NPSFilter, setNPSFilter] = useState([]);
  const [UnidadeFilter, setUnidadeFilter] = useState([]);
  const [FaixaEtariaFilter, setFaixaEtariaFilter] = useState([]);

  //dados dos filtros
  const [itemsNPS, setItemsNPS] = useState([]);
  const [itemsUnidade, setItemsUnidade] = useState([]);
  const [itemsFaixa, setItemsFaixa] = useState([]);

  const getInitData = () => {
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, [], {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));

        //setando filtros
        //nps
        let filter0 = data.filter.NPS.map((u) => {
          return { value: u, label: u };
        });
        setItemsNPS(filter0);
        //unidade
        let filter1 = data.filter.Unidade.map((u) => {
          return { value: u, label: u };
        });
        setItemsUnidade(filter1);
        //faixa
        let filter4 = data.filter["Idade Aluno"].map((u) => {
          return { value: u, label: u };
        });
        setItemsFaixa(filter4);
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const doFilter = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    dispatch(setResponseLight(null));
    setLocalLoad(true);
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, filterBase, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const getExcel = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    api
      .post(
        "https://backpearson.inovyo.com/api/download/" + dashid,
        filterBase,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((result) => {
        const { data } = result;
        if (data.status) {
          setHasFile(data.file);
        } else {
          setHasFile("error");
        }
      })
      .catch(() => {
        setHasFile("error");
      });
  };

  useEffect(() => {
    getInitData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isModalExcelOpen) {
      setTimeout(() => {
        getExcel();
      }, 1500);
    }
    // eslint-disable-next-line
  }, [isModalExcelOpen]);


  // const ContentFullScreen = () => {
  //   switch (chartType) {
  //     case "WorldCloud":
  //       return (
  //         <WorldCloud
  //           data={dataJSON[fullScreenTitle]}
  //           title={fullScreenTitle}
  //         />
  //       );
  //     case "BarChart":
  //       return (
  //         <BarChart
  //           data={dataJSON[fullScreenTitle]}
  //           style={{ height: "100%" }}
  //         />
  //       );
  //     case "PieChart":
  //       return <PieChart data={dataJSON[fullScreenTitle]} />;

  //     default:
  //       return <></>;
  //   }
  // };

  return (
    <>
      {localLoad && dataJSON === null && (
        <div className="loading-data">
          <span>Carregando dados...</span> <br />
          Isto pode levar alguns segundos!
        </div>
      )}
      {!localLoad && dataJSON === null && (
        <div className="loading-error">
          <span>X</span> <br />
          Erro ao carregar dados!
        </div>
      )}
      {!localLoad && dataJSON !== null && (
        <>
          <MainMenu
            design="pw"
            items={[
              {
                icon: "home",
                label: "Geral",
                href: "#nps",
              },
              {
                icon: "user",
                label: "Aluno",
                href: "#aluno",
              },
              {
                icon: "book",
                label: "Onboarding",
                href: "#onboarding",
              },
              {
                icon: "book",
                label: "Experiência de aprendizado",
                href: "#aprendizado",
              },
              {
                icon: "book",
                label: "Rematricula",
                href: "#rematricula",
              },
              {
                icon: "book",
                label: "Alcance de objetivos",
                href: "#objetivos",
              },
              {
                icon: "list",
                label: "Respondentes",
                href: "#respondentes",
              },
            ]}
          />
          <div
            id="dahboard-light"
            style={
              activeMenu
                ? { width: "calc(100% - 120px)", marginLeft: "120px" }
                : { width: "100%", marginLeft: "0px" }
            }
          >
            <div
              className="filter-box"
              style={
                activeMenu ? { width: "calc(100% - 120px)" } : { width: "100%" }
              }
            >
              <Button
                type="primary"
                icon={<FaFilter />}
                onClick={() => setIsModalOpen(true)}
              >
                &nbsp; Filtrar
              </Button>

              {NPSFilter.length > 0 && (
                <div className="item">
                  <span>NPS</span>
                  <div className="filtros">{NPSFilter.join(", ")}</div>
                </div>
              )}

              {UnidadeFilter.length > 0 && (
                <div className="item">
                  <span>UNIDADE</span>
                  <div className="filtros">{UnidadeFilter.join(", ")}</div>
                </div>
              )}

              {FaixaEtariaFilter.length > 0 && (
                <div className="item">
                  <span>FAIXA ETÁRIA</span>
                  <div className="filtros">{FaixaEtariaFilter.join(", ")}</div>
                </div>
              )}

              <Modal
                title="Filtrar"
                open={isModalOpen}
                onOk={() => {
                  setIsModalOpen(false);
                  doFilter();
                }}
                onCancel={() => setIsModalOpen(false)}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="nps-filter">NPS</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="nps-filter"
                    options={itemsNPS}
                    value={NPSFilter}
                    onChange={(newValue) => {
                      setNPSFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="unidade-filter">Unidade</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="unidade-filter"
                    options={itemsUnidade}
                    value={UnidadeFilter}
                    onChange={(newValue) => {
                      setUnidadeFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="faixa-filter">Faixa Etária</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="faixa-filter"
                    options={itemsFaixa}
                    value={FaixaEtariaFilter}
                    onChange={(newValue) => {
                      setFaixaEtariaFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
              </Modal>
            </div>
            {tab === "#nps" && (
              <>
                <Row>
                  <Col span={8}>
                    <CardKPI
                      label="Respostas Total"
                      value={dataJSON["responsesCount"]}
                      icon="users"
                      bgColor="#002A4E"
                    />
                  </Col>
                  <Col span={8}>
                    <CardKPI
                      label="Respostas NPS"
                      value={dataJSON["npsCount"]}
                      icon="users"
                      bgColor="#002A4E"
                    />
                  </Col>
                  <Col span={8}>
                    <CardKPI
                      label="NPS"
                      value={dataJSON["npsNumber"]}
                      icon="circles"
                      bgColor="#FFC400"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      id="nps-dist"
                      type="NPS"
                      title="NPS Distribuição"
                      question="De 0 a 10, o quanto você recomendaria a Wizard para algum familiar ou amigo?"
                      chart={{
                        data: dataJSON["npsDist"],
                        fullscreen: false
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="LineChart"
                      title="NPS Histórico"
                      chart={{
                        data: dataJSON["npsHistoric"],
                        style: { height: "210px" }
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Motivo NPS - Promotor"
                      question={<>
                        Ficamos muito felizes com seu feedback!
                        Quais são as principais razões pelas quais
                        você recomendaria a Wizard?
                      </>}
                      chart={{
                        data: dataJSON["Motivo NPS - Promotor"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Motivo NPS - Promotor"
                      chart={{
                        data: dataJSON["WORDCLOUD | Motivo NPS - Promotor"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Motivo NPS - Neutro e detrator"
                      question="O que falta para que a Wizard seja melhor?"
                      chart={{
                        data: dataJSON["Motivo NPS - Neutro e detrator"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Motivo NPS - Neutro e detrator"
                      chart={{
                        data: dataJSON["WORDCLOUD | Motivo NPS - Neutro e detrator"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="É aluno Wizard"
                      question="Você é aluno(a) Wizard?"
                      chart={{
                        data: dataJSON["É aluno"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="NPS x Unidade"
                      style={{ margin: 10, height: "350px" }}
                      styles={{ body: { padding: 0, height: "290px" } }}
                      chart={{
                        data: dataJSON["NPSxRespondentes"],
                        style: { height: "220px" },
                        className: "bar-chart-inovyo",
                        design: "relationalBarYellow"
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === "#aluno" && (
              <>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Idade Aluno"
                      question={<>
                        Qual a idade dele/dela?
                        <br />
                        <br />
                        Qual a sua idade?
                      </>}
                      chart={{
                        data: dataJSON["Idade Aluno"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Idioma que estuda"
                      question={<>
                        Agora que conhecemos um pouquinho sobre
                        você, queremos saber sobre o seu curso na
                        Wizard! Qual idioma você estuda na Wizard?
                        Para o caso de estudar mais de um idioma,
                        escolha o mais relevante nesse momento.
                        <br />
                        <br />
                        Qual idioma ele(a) estuda na Wizard? Para
                        o caso de estudar mais de um idioma,
                        escolha o mais relevante nesse momento.
                      </>}
                      chart={{
                        data: dataJSON["Idioma que estuda"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Escolaridade"
                      question={<>
                        Selecione o nível de escolaridade que você
                        está cursando atualmente ou o nível mais
                        recente que já concluiu
                        <br />
                        <br />
                        Pensando em escolaridade, em que nível da
                        educação básica seu filho(a) está
                        atualmente?
                      </>}
                      chart={{
                        data: dataJSON["Escolaridade"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Tipo de instituição que estuda"
                      question={<>
                        Em qual tipo de instituição você estuda
                        atualmente? Caso você não estude
                        atualmente, em qual tipo de instituição
                        você estudou majoritariamente?
                        <br />
                        <br />
                        Em qual tipo de instituição ele/ela estuda
                        atualmente?
                      </>}
                      chart={{
                        data: dataJSON["Tipo de instituição que estuda"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Formato de aulas"
                      question={<>
                        Qual é o seu formato de aulas na Wizard?
                        <br />
                        <br />
                        Qual é o formato de aulas do seu filho/da
                        sua filha na Wizard?
                      </>}
                      chart={{
                        data: dataJSON["Formato de aulas"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Modalidade"
                      question={<>
                        Qual é a sua modalidade de aulas na
                        Wizard?
                        <br />
                        <br />
                        Qual é a modalidade de aulas do seu
                        filho/da sua filha na Wizard?
                      </>}
                      chart={{
                        data: dataJSON["Modalidade"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Tempo de Escola"
                      question={<>
                        Agora que conhecemos um pouquinho sobre
                        você, queremos saber sobre o seu curso na
                        Wizard!Há quanto tempo você estuda na
                        Wizard?
                        <br />
                        <br />
                        Agora que conhecemos um pouquinho sobre o
                        seu filho/a sua filha, queremos saber
                        sobre o curso dele/dela na Wizard!Há
                        quanto tempo ele(a) estuda na Wizard?
                      </>}
                      chart={{
                        data: dataJSON["Tempo de Escola"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Motivos para escolher a Wizard"
                      question={<>
                        Quais foram as razões que levaram você a
                        escolher a Wizard, em comparação com
                        outras escolas/marcas que considerou?
                      </>}
                      chart={{
                        data: dataJSON["Motivos para escolher a Wizard"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Motivos para escolher a Wizard"
                      chart={{
                        data: dataJSON["WORDCLOUD | Motivos para escolher a Wizard"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Livro/Nível que está"
                      question={<>
                        Em que livro/nível você está?
                        <br />
                        Em que livro/nível do inglês você está?
                        <br />
                        <br />
                        Em que livro/nível o seu filho/a sua filha
                        está?
                        <br />
                        Em que livro/nível do inglês o seu filho/a
                        sua filha está?
                      </>}
                      chart= {{
                        data: dataJSON["Livro/Nível que está"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Capa Kids 2"
                      question={<>
                        Das duas opções de capa de livro abaixo,
                        qual é a sua?
                        <br />
                        <br />
                        Das duas opções de capa de livro abaixo,
                        qual é do seu filho/ da sua filha?
                      </>}
                      chart={{
                        data: dataJSON["Capa Kids 2"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Capa Teens 6"
                      question={<>
                        Das duas opções de capa de livro abaixo,
                        qual é a sua?
                        <br />
                        <br />
                        Das duas opções de capa de livro abaixo,
                        qual é do seu filho/ da sua filha?
                      </>}
                      chart={{
                        data: dataJSON["Capa Teens 6"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Capa W10"
                      question={<>
                        Das duas opções de capa de livro abaixo,
                        qual é a sua?
                        <br />
                        <br />
                        Das duas opções de capa de livro abaixo,
                        qual é do seu filho/ da sua filha?
                      </>}
                      chart={{
                        data: dataJSON["Capa W10"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Tempo que está no livro"
                      question={<>
                        Há quanto tempo você está estudando no
                        livro
                        <br />
                        <br />
                        Há quanto tempo seu filho/sua filha está
                        estudando no livro
                      </>}
                      chart={{
                        data: dataJSON["Tempo que está no livro"]
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === "#onboarding" && (
              <>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Motivo da escolha 100% presencial"
                      question={<>
                        O que te motivou a escolher o formato de
                        estudo 100% presencial?
                        <br />
                        <br />O que te motivou a escolher o
                        formato de estudo 100% presencial para seu
                        filho/sua filha?
                      </>}
                      chart={{
                        data: dataJSON["Motivo da escolha 100% presencial"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Motivo da escolha 100% presencial"
                      chart={{
                        data: dataJSON["WORDCLOUD | Motivo da escolha 100% presencial"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Motivo da escolha 100% online"
                      question={<>
                        O que te motivou a escolher o formato de
                        estudo 100% online?
                        <br />
                        <br />O que te motivou a escolher o
                        formato de estudo 100% online para seu
                        filho/sua filha?
                      </>}
                      chart={{
                        data: dataJSON["Motivo da escolha 100% online"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Motivo da escolha 100% online"
                      chart={{
                        data: dataJSON["WORDCLOUD | Motivo da escolha 100% online"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATCombo"
                      title="Satisfação Material Didático"
                      question={<>
                        Numa escala de 1 a 5, o quão satisfeito
                        você está com o material didático que está
                        sendo utilizado nesse momento?
                      </>}
                      chart={{
                        data: dataJSON["Satisfação Material Didático (Q1)"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="O que precisa melhorar Material Didático"
                      question={<>
                        O que falta para que o material didático
                        da Wizard seja melhor?
                      </>}
                      chart={{
                        data: dataJSON["O que precisa melhorar Material Didático (Q1)"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | O que precisa melhorar Material Didático (Q1)"
                      chart={{
                        data: dataJSON["WORDCLOUD | O que precisa melhorar Material Didático (Q1)"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Experiência nas aulas"
                      question={<>
                        Agora queremos entender um pouco mais
                        sobre sua experiência com as aulas
                        <br />
                        Pensando na sua experiência de aulas, o
                        quanto você concorda com as frases abaixo?
                        <br />
                        <br />
                        Agora queremos entender um pouco mais
                        sobre a experiência do seu filho/da sua
                        filha com as aulas.
                        <br />
                        Pensando na experiência do seu filho/da
                        sua filha, o quanto você concorda com as
                        frases abaixo?
                      </>}
                      chart={{
                        data: dataJSON["Como melhorar experiência"]
                      }}
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Como melhorar experiência"
                      question={<>
                        O que poderíamos fazer para melhorar a sua
                        experiência com as aulas da Wizard?
                        <br />
                        <br />O que poderíamos fazer para melhorar
                        a experiência do seu filho/da sua filha
                        com as aulas da Wizard?
                      </>}
                      chart={{
                        data: dataJSON["Como podemos melhorar a experiência com as aulas da Wizard"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Como podemos melhorar a experiência com as aulas da Wizard"
                      chart={{
                        data: dataJSON["WORDCLOUD | Como podemos melhorar a experiência com as aulas da Wizard"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Por que a experiência é boa"
                      question={<>
                        Quais são as características da Wizard que
                        mais contribuem para a sua boa experiência
                        com as aulas?
                        <br />
                        <br />
                        Quais são as características da Wizard que
                        mais contribuem para a boa experiência do
                        seu filho/sua filha com as aulas?
                      </>}
                      chart={{
                        data: dataJSON["O que mais contribui para uma a boa experiência com as aulas da Wizard"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | O que mais contribui para uma a boa experiência com as aulas da Wizard"
                      chart={{
                        data: dataJSON["WORDCLOUD | O que mais contribui para uma a boa experiência com as aulas da Wizard"]
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === "#aprendizado" && (
              <>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Avaliação do(a) professor(a)"
                      question={<>
                        Pensando no seu(sua) professor(a), o
                        quanto você concorda com as afirmativas
                        abaixo?
                        <br />
                        <br />
                        Pensando no(a) professor(a) do seu
                        filho/da sua filha, o quanto você concorda
                        com as afirmativas abaixo?
                      </>}
                      chart={{
                        data: dataJSON["Avaliação do(a) professor(a)"]
                      }}
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Objetivos alcançado com aprendizado de um idioma"
                      question={<>
                        O aprendizado de um idioma te ajuda a
                        alcançar qual objetivo? Escolha apenas um
                        (o principal)
                        <br />
                        <br />O aprendizado de um idioma ajuda seu
                        filho(a) a alcançar qual objetivo? Escolha
                        apenas um (o principal)
                      </>}
                      chart={{
                        data: dataJSON["Objetivos alcançado com aprendizado de um idioma"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Aprendizados que gostaria de conquistar"
                      question={<>
                        Quais são os principais aprendizados que
                        você gostaria de conquistar ao final desse
                        curso? (selecione no máximo 3 opções)
                        <br />
                        <br />
                        Quais são os principais aprendizados que
                        você gostaria que seu filho/sua filha
                        conquistasse ao final desse curso?
                        (selecione até 3 opções)
                      </>}
                      chart={{
                        data: dataJSON["Aprendizados que gostaria de conquistar"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="O quanto a Wizard está ajudando a alcançar as conquistas"
                      question={<>
                        De 1 a 5, o quanto você acredita que a
                        Wizard está te ajudando a conquistar os
                        principais aprendizados que você mencionou
                        anteriormente?
                        <br />
                        <br />
                        De 1 a 5, o quanto você acredita que a
                        Wizard está ajudando seu filho(a) a
                        conquistar os principais aprendizados que
                        você mencionou anteriormente?
                      </>}
                      chart={{
                        data: dataJSON["O quanto a Wizard está ajudando a alcançar as conquistas"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Por que não contribui"
                      question={<>
                        O que te motivou a escolher o formato de
                        estudo 100% presencial?
                        <br />
                        <br />O que te motivou a escolher o
                        formato de estudo 100% presencial para seu
                        filho/sua filha?
                      </>}
                      chart={{
                        data: dataJSON["Por que não contribui"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Por que não contribui"
                      chart={{
                        data: dataJSON["WORDCLOUD | Por que não contribui"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Características que mais contribuem"
                      question={<>
                        O que te motivou a escolher o formato de
                        estudo 100% online?
                        <br />
                        <br />O que te motivou a escolher o
                        formato de estudo 100% online para seu
                        filho/sua filha?
                      </>}
                      chart={{
                        data: dataJSON["Características que mais contribuem"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Características que mais contribuem"
                      chart={{
                        data: dataJSON["WORDCLOUD | Características que mais contribuem"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="TableChart"
                      title="Nome Professor"
                      question={<>
                        Qual é o nome do seu professor?
                        <br />
                        <br />
                        Qual é o nome do professor do seu filho/da
                        sua filha?
                      </>}
                      chart={{
                        data: dataJSON["Nome Professor (Q2)"]
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === "#rematricula" && (
              <>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="PieChart"
                      title="Pretende continuar na Wizard no próximo semestre"
                      question={<>
                        Você pretende continuar estudando na
                        Wizard no próximo semestre?
                        <br />
                        <br />
                        Você pretende manter seu filho/sua filha
                        matriculado na Wizard no próximo semestre?
                      </>}
                      chart={{
                        data: dataJSON["Pretende continuar na Wizard no próximo semestre"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Motivo para continuar na Wizard"
                      question={<>
                        Qual é o principal motivo pelo qual você
                        pretende continuar estudando na Wizard?
                        <br />
                        <br />
                        Qual é o principal motivo pelo qual você
                        pretende manter seu filho/sua filha
                        estudando na Wizard?
                      </>}
                      chart={{
                        data: dataJSON["Motivo para continuar na Wizard"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Motivo para não continuar na Wizard"
                      question={<>
                        Que pena! ☹ Quais são as principais razões
                        pelas quais você não pretende continuar
                        estudando na Wizard? (selecione no máximo
                        3 opções)
                        <br />
                        <br />
                        Que pena! ☹ Quais são as principais razões
                        pelas quais você não pretende manter seu
                        filho(a) na Wizard? (selecione no máximo 3
                        opções)
                      </>}
                      chart={{
                        data: dataJSON["Motivo para não continuar na Wizard"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="O que podemos fazer para continuar conosco"
                      question={<>
                        O que poderíamos fazer para você continuar
                        estudando conosco?
                        <br />
                        <br />O que poderíamos fazer para seu
                        filho/sua filha continuar estudando
                        conosco?
                      </>}
                      chart={{
                        data: dataJSON["O que podemos fazer para continuar conosco"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | O que podemos fazer para continuar conosco"
                      chart={{
                        data: dataJSON["WORDCLOUD | O que podemos fazer para continuar conosco"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Escola de idiomas que irá"
                      question={<>
                        Para qual escola de idiomas você irá?
                        <br />
                        <br />
                        Para qual escola de idiomas seu filho/sua
                        filha irá?
                      </>}
                      chart={{
                        data: dataJSON["Escola de idiomas que irá"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="O que podemos fazer para você aprender melhor"
                      question={<>
                        Existe algo que a Wizard poderia fazer
                        para você aprender melhor o idioma?
                        <br />
                        <br />
                        Existe algo que a Wizard poderia fazer
                        para seu filho/sua filha aprender melhor o
                        idioma?
                      </>}
                      chart={{
                        data: dataJSON["O que podemos fazer para você aprender melhor"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | O que podemos fazer para você aprender melhor"
                      chart={{
                        data: dataJSON["WORDCLOUD | O que podemos fazer para você aprender melhor"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Qual expectativa não foi cumprida"
                      question={<>
                        Quais foram as expectativas que a Wizard
                        não atendeu ou divergências entre o que
                        foi prometido no início do curso e a
                        realidade?
                      </>}
                      chart={{
                        data: dataJSON["Qual expectativa não foi cumprida"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Qual expectativa não foi cumprida"
                      chart={{
                        data: dataJSON["WORDCLOUD | Qual expectativa não foi cumprida"]
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === "#objetivos" && (
              <>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Interesse em cursos temáticos"
                      question={<>
                        Você teria interesse em fazer cursos
                        temáticos de inglês (ex. inglês para
                        negócios, inglês para viagens, inglês para
                        conversação) de curta duração?
                        <br />
                        <br />
                        Você teria interesse que seu filho/sua
                        filha faça cursos temáticos de inglês (ex.
                        inglês para negócios, inglês para viagens,
                        inglês para conversação) de curta duração?
                      </>}
                      chart={{
                        data: dataJSON["Interesse em cursos temáticos"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Quais cursos temáticos teria interesse"
                      question={<>
                        Em quais cursos temáticos de inglês você
                        teria interesse?(selecione no máximo 3
                        opções)
                        <br />
                        <br />
                        Quais cursos temáticos de inglês você
                        teria interesse que seu filho/sua filha
                        faça?(selecione no máximo 3 opções)
                      </>}
                      chart={{
                        data: dataJSON["Quais cursos temáticos teria interesse"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Fator determinante para se matricular num curso temático"
                      question={<>
                        Qual fator seria mais determinante para
                        você de fato se matricular num curso
                        temático de inglês?
                        <br />
                        <br />
                        Qual fator seria mais determinante para
                        você de fato matricular seu filho/sua
                        filha num curso temático de inglês?
                      </>}
                      chart={{
                        data: dataJSON["Fator determinante para se matricular num curso temático"]
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Interesse em estudar outro idioma"
                      question={<>
                        Você teria interesse em estudar outro
                        idioma na Wizard?
                        <br />
                        <br />
                        Você teria interesse que seu filho/sua
                        filha estude outro idioma na Wizard?
                      </>}
                      chart={{
                        data: dataJSON["Interesse em estudar outro idioma"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Quais idiomas teria interesse"
                      question={<>
                        Em qual(is) idioma(s) você teria
                        interesse?
                        <br />
                        <br />
                        Em qual(is) idioma(s) você teria interesse
                        que seu filho/sua filha estude?
                      </>}
                      chart={{
                        data: dataJSON["Quais idiomas teria interesse"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Avaliação da unidade - Presencial"
                      question={<>
                        Pensando em sua escola, como você avalia
                        os itens a seguir
                        <br />
                        <br />
                        Pensando na escola de seu filho/sua filha,
                        como você avalia os itens a seguir
                      </>}
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                      chart={{
                        data: dataJSON["Avaliação da unidade - Presencial"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Sugestão de melhoria para estrutura física"
                      question={<>
                        Você tem alguma sugestão de melhoria para
                        a estrutura física da escola onde você
                        estuda?
                        <br />
                        <br />
                        Você tem alguma sugestão de melhoria para
                        a estrutura física da escola onde seu
                        filho/sua filha estuda?
                      </>}
                      chart={{
                        data: dataJSON["Sugestão de melhoria para estrutura física"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Sugestão de melhoria para estrutura física"
                      chart={{
                        data: dataJSON["WORDCLOUD | Sugestão de melhoria para estrutura física"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Avaliação da unidade - Online"
                      question={<>
                        O quanto você concorda com cada uma das
                        afirmativas abaixo?
                      </>}
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                      chart={{
                        data: dataJSON["Avaliação da unidade - Online"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Sugestão de melhoria para a escola"
                      question={<>
                        Você tem alguma sugestão de melhoria em
                        relação à escola onde você está
                        matriculado(a)?
                        <br />
                        <br />
                        Você tem alguma sugestão de melhoria em
                        relação à escola onde seu filho/filha está
                        matriculado(a)?
                      </>}
                      chart={{
                        data: dataJSON["Sugestão de melhoria para a escola"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Sugestão de melhoria para a escola"
                      chart={{
                        data: dataJSON["WORDCLOUD | Sugestão de melhoria para a escola"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Avaliação das ferramentas"
                      question={<>
                        Pensando nas ferramentas que você utiliza
                        ou já utilizou pelo menos uma vez em seu
                        curso na Wizard, qual é o seu nível de
                        satisfação com
                        <br />
                        <br />
                        Pensando sobre as ferramentas que seu
                        filho(a) utiliza ou já utilizou pelo menos
                        uma vez em seu curso na Wizard, qual é o
                        seu nível de satisfação com
                      </>}
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                      chart={{
                        data: dataJSON["Avaliação das ferramentas"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Precisa melhorar Ferramentas da Wizard"
                      question={<>
                        O que falta para que as ferramentas da
                        Wizard sejam melhores?
                      </>}
                      chart={{
                        data: dataJSON["Precisa melhorar Ferramentas da Wizard (Q4)"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Precisa melhorar Ferramentas da Wizard (Q4)"
                      chart={{
                        data: dataJSON["WORDCLOUD | Precisa melhorar Ferramentas da Wizard (Q4)"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Pontos positivos Ferramentas da Wizard"
                      question={<>
                        O que faz com que as ferramentas da Wizard
                        sejam boas e contribuam para o seu
                        aprendizado?
                        <br />
                        <br />O que faz com que as ferramentas da
                        Wizard sejam boas e contribuam para o
                        aprendizado do seu(sua) filho(a)?
                      </>}
                      chart={{
                        data: dataJSON["Pontos positivos Ferramentas da Wizard"]
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Pontos positivos Ferramentas da Wizard"
                      chart={{
                        data: dataJSON["WORDCLOUD | Pontos positivos Ferramentas da Wizard"]
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Utiliza mais alguma ferramenta"
                      question={<>
                        Você utiliza mais alguma ferramenta para
                        as aulas da Wizard além dessas que foram
                        citadas?
                        <br />
                        <br />
                        Seu filho(a) utiliza mais alguma
                        ferramenta para as aulas da Wizard além
                        dessas que foram citadas?
                      </>}
                      chart={{
                        data: dataJSON["Utiliza mais alguma ferramenta"]
                      }}
                    />
                  </Col>
                  {/* <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
              body: { padding: "0px" }
            }}
                      title="Outros - Utiliza mais alguma ferramenta"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart
                        data={
                          dataJSON["Outros - Utiliza mais alguma ferramenta (Q4)"]
                        }
                      />
                    </Card>
                  </Col> */}
                </Row>
              </>
            )}
            {tab === "#respondentes" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      styles={{ body: { padding: 5 } }}
                    >
                      <Button
                        value={"Download de Base"}
                        type="primary"
                        size="small"
                        onClick={() => setIsModalExcelOpen(true)}
                      >
                        Download de Base
                      </Button>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="TableChart"
                      chart={{
                        data: dataJSON["Respondentes"],
                        design: "respondentes",
                        fullscreen: false
                      }}
                      style={{ margin: 10 }}
                      styles={{}}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Modal
              title="Download de Base"
              open={isModalExcelOpen}
              onOk={() => {
                setIsModalExcelOpen(false);
                setHasFile("");
              }}
              okText="Fechar"
              closable={false}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              {hasFile === "" && (
                <div id="link-excel" className="process">
                  <RiFileDownloadFill /> Aguarde.. extraindo dados...
                </div>
              )}

              {hasFile !== "" && hasFile !== "error" && (
                <a
                  href={hasFile}
                  target="_blank"
                  rel="noreferrer"
                  id="link-excel"
                >
                  <RiFileExcel2Fill /> Baixar Arquivo
                </a>
              )}

              {hasFile !== "" && hasFile === "error" && (
                <div id="link-excel" className="error">
                  <RiCloseCircleFill /> Falha ao baixar arquivo!
                </div>
              )}
            </Modal>

            <Modal
              title={titleFullScreen}
              open={modalVisible}
              onOk={() => dispatch(setIsModalVisible(false))}
              onCancel={() => {
                dispatch(setIsModalVisible(false))
                dispatch(setChartType(""))
                dispatch(setChartData(null))
                dispatch(setFullScreenTitle(""))
              }}
              footer={null}
              width={ window.innerWidth }
            >
              <CreateElement />
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default PearsonWizard2024;
