import React from "react";
import { Col, Row, Card, Popover } from "antd";
import LineChart from "../Line";

//styles
import "../../../../assets/css/dashboard-light/charts/csat-combo.css";

//charts
import AccelerationChart from "../Acceleration";

const CSATCombo = (props) => {
  let { data: dataJson, positions, language } = props;

  const csatDesc = {
    espanhol: {
      medias: "Medias",
      respostas: "Respuestas",
      satisfeitos: "CSAT (Satisfecho)",
      cardTitle1: "Histórico de Média",
    },
  }[language] || {
    medias: "Médias",
    respostas: "Respostas",
    satisfeitos: "CSAT (Satisfeitos)",
    cardTitle1: "Histórico de Média",
  };

  const csatData = Object.values(dataJson.csat);
  const csatDataitems = Object.keys(dataJson.csat);
  var totalRespostas = csatData.reduce((soma, i) => soma + i, 0);

  const getAvg = () => {
    var avg = 0;
    csatDataitems.forEach((csatItem, i) => {
      if (positions && positions === "0-10") {
        if (csatItem !== "0") {
          avg += csatItem * csatData[i];
        }
      } else {
        avg += csatItem * csatData[i];
      }
    });
    return avg / totalRespostas;
  };

  const getSatis = () => {
    if (positions && positions === "0-10") {
      var totalRespostas7a10 = 0;
      csatDataitems.forEach((keyCSAT) => {
        totalRespostas7a10 += ["9", "10"].includes(keyCSAT)
          ? dataJson.csat[keyCSAT]
          : 0;
      });
      const calc = (totalRespostas7a10 / totalRespostas) * 100;
      return calc;
    } else {
      let totalRespostas4e5 = 0;
      csatDataitems.forEach((item) => {
        if (["4", "5"].includes(item)) {
          totalRespostas4e5 += dataJson.csat[item];
        }
      });
      const calc = (totalRespostas4e5 / totalRespostas) * 100;
      return calc;
    }
  };

  var media = getAvg().toFixed(1);
  var satisfacao = getSatis().toFixed(1);

  const GenerateHorizontalLines = () => {
    let horizontalBars = null;

    //csat e 10 posições
    if (positions && positions === "0-10") {
      let agrupamaneto = {};

      csatDataitems.forEach((a) => {
        const originalA = parseInt(a);
        a = ["0", "1", "2", "3", "4", "5", "6"].includes(a) ? "0-6" : a;

        if (agrupamaneto[a]) {
          agrupamaneto[a] += dataJson.csat[originalA];
        } else {
          agrupamaneto[a] = dataJson.csat[originalA];
        }
      });

      let keysAgrupamentoBase = ["0-6", "7", "8", "9", "10"];
      let keysAgrupamento = [];

      keysAgrupamentoBase.forEach((keyMap) => {
        if (Object.keys(agrupamaneto).includes(keyMap)) {
          keysAgrupamento.push(keyMap);
        }
      });

      horizontalBars = keysAgrupamento.map((hl) => {
        const calcWithBar = (agrupamaneto[hl] / totalRespostas) * 100;

        return (
          <Popover
            content={
              <p>
                {parseInt(calcWithBar)}%: {new Intl.NumberFormat("pt-BR").format(agrupamaneto[hl])}{" "}
                {csatDesc["respostas"].toLowerCase()}
              </p>
            }
            title={`NOTA: ${hl}`}
            key={"bar-csat" + Math.random()}
          >
            <div className={`bar${hl}`} style={{ width: calcWithBar + "%" }}>
              {parseInt(calcWithBar)}%
            </div>
          </Popover>
        );
      });
    } else {
      //csat comum de 1 a 5
      horizontalBars = csatDataitems.map((hl) => {
        if(dataJson.csat[hl]>0){
          const calcWithBar = (dataJson.csat[hl] / totalRespostas) * 100;
          return (
            <Popover
              content={
                <p>
                  {parseInt(calcWithBar)}%: {new Intl.NumberFormat("pt-BR").format(dataJson.csat[hl])}{" "}
                  {csatDesc["respostas"].toLowerCase()}
                </p>
              }
              title={`NOTA: ${hl}`}
              key={"bar-csat" + Math.random()}
            >
              <div className={`bar${hl}`} style={{ width: calcWithBar + "%" }}>
                {parseInt(calcWithBar)}%
              </div>
            </Popover>
          );
        }
      });
    }

    let agrupandoLegend = [];
    let horizontalBarsLegend = null;

    if (positions && positions === "0-10") {
      csatDataitems.forEach((a) => {
        a = ["0", "1", "2", "3", "4", "5", "6"].includes(a) ? "0-6" : a;

        if (!agrupandoLegend.includes(a)) {
          agrupandoLegend.push(a);
        }
      });

      horizontalBarsLegend = agrupandoLegend.map((hl) => {
        return (
          <div className={`legend${hl}`} key={"bar-csat" + Math.random()}>
            {hl}
          </div>
        );
      });
    } else {
      horizontalBarsLegend = csatDataitems.map((hl) => {
        return (
          <div className={`legend${hl}`} key={"bar-csat" + Math.random()}>
            {hl}
          </div>
        );
      });
    }

    return (
      <div className="box-horizontal-csat">
        <div className="content">{horizontalBars}</div>
        <div className="legend">{horizontalBarsLegend}</div>
      </div>
    );
  };

  return (
    <>
      <div id="csat-combo-inovyo">
        <table className="details-table">
          <tbody>
            <tr>
              <td>
                <strong>{csatDesc.medias}</strong>
              </td>
              <td>
                <strong>{csatDesc.respostas}</strong>
              </td>
              <td>
                <strong>{csatDesc.satisfeitos}</strong>
              </td>
            </tr>
            <tr>
              <td>{media}</td>
              <td>{new Intl.NumberFormat("pt-BR").format(totalRespostas)}</td>
              <td>{satisfacao}%</td>
            </tr>
          </tbody>
        </table>
        <Row>
          <Col span={24}>
            <GenerateHorizontalLines />
          </Col>
        </Row>
        <Row>
          <Col span={8} style={{ textAlign: "center" }}>
            <AccelerationChart data={dataJson} />
          </Col>
          <Col span={16} style={{ textAlign: "center" }}>
            <Card
              style={{ height: "300px", border: "none" }}
              title={csatDesc.cardTitle1}
            >
              <LineChart
                style={{ height: "210px" }}
                data={dataJson["historic"]}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CSATCombo;
