import React, { useState, useEffect, useContext } from "react";
import { Space, Button } from "antd";
import { Col, Row, Card, Select, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FaFilter, FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import {
  RiFileExcel2Fill,
  RiCloseCircleFill,
  RiFileDownloadFill,
} from "react-icons/ri";

import api from "../../../service/api";

//components
import MainMenu from "../Menu";
import { CardKPI } from "../Card";

//charts
import LineChart from "../Charts/Line";
import PieChart from "../Charts/Pie";
import BarChart from "../Charts/Bar";
import NPS from "../Charts/NPS";
import CSATCombo from "../Charts/CSATCombo";
import CSATLines from "../Charts/CSATLines";
import TableChart from "../Charts/Table";
import WorldCloud from "../Charts/WorldCloud";

//data
//import dataJSON from "../data.json";

//redux
import {
  tabActive,
  menuOpen,
  setResponseLight,
  responseLight,
  setInfoModal,
} from "../../../store/dashboard/dashboard.slice";

//providers
import { Context } from "../../../providers/AuthProvider";

const PearsonWizardEspanhol = () => {
  const { dashid } = useParams();
  let { token } = useContext(Context);

  const tab = useSelector(tabActive);
  const activeMenu = useSelector(menuOpen);
  const dataJSON = useSelector(responseLight);
  const dispatch = useDispatch();

  const [localLoad, setLocalLoad] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExcelOpen, setIsModalExcelOpen] = useState(false);
  const [hasFile, setHasFile] = useState("");

  const [NPSFilter, setNPSFilter] = useState([]);
  const [UnidadeFilter, setUnidadeFilter] = useState([]);
  const [FaixaEtariaFilter, setFaixaEtariaFilter] = useState([]);

  //dados dos filtros
  const [itemsNPS, setItemsNPS] = useState([]);
  const [itemsUnidade, setItemsUnidade] = useState([]);
  const [itemsFaixa, setItemsFaixa] = useState([]);

  const getInitData = () => {
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, [], {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));

        //setando filtros
        //nps
        let filter0 = data.filter.NPS.map((u) => {
          return { value: u, label: u };
        });
        setItemsNPS(filter0);
        //unidade
        let filter1 = data.filter.Unidade.map((u) => {
          return { value: u, label: u };
        });
        setItemsUnidade(filter1);
        //faixa
        let filter4 = data.filter["Idade Aluno"].map((u) => {
          return { value: u, label: u };
        });
        setItemsFaixa(filter4);
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const doFilter = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    dispatch(setResponseLight(null));
    setLocalLoad(true);
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, filterBase, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const getExcel = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    api
      .post(
        "https://backpearson.inovyo.com/api/download/" + dashid,
        filterBase,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((result) => {
        const { data } = result;
        if (data.status) {
          setHasFile(data.file);
        } else {
          setHasFile("error");
        }
      })
      .catch(() => {
        setHasFile("error");
      });
  };

  useEffect(() => {
    getInitData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isModalExcelOpen) {
      setTimeout(() => {
        getExcel();
      }, 1500);
    }
    // eslint-disable-next-line
  }, [isModalExcelOpen]);

  return (
    <>
      {localLoad && dataJSON === null && (
        <div className="loading-data">
          <span>Carregando dados...</span> <br />
          Isto pode levar alguns segundos!
        </div>
      )}
      {!localLoad && dataJSON === null && (
        <div className="loading-error">
          <span>X</span> <br />
          Erro ao carregar dados!
        </div>
      )}
      {!localLoad && dataJSON !== null && (
        <>
          <MainMenu
            design="pw"
            items={[
              {
                icon: "smile",
                label: "NPS",
                href: "#nps",
              },
              {
                icon: "user",
                label: "Alumno",
                href: "#aluno",
              },
              {
                icon: "toolbox",
                label: "Materiales / Herramientas",
                href: "#tools",
              },
              {
                icon: "chalkboard-teacher",
                label: "Profesor",
                href: "#teacher",
              },
              {
                icon: "list",
                label: "Encuestados",
                href: "#respondentes",
              },
            ]}
          />
          <div
            id="dahboard-light"
            style={
              activeMenu
                ? { width: "calc(100% - 120px)", marginLeft: "120px" }
                : { width: "100%", marginLeft: "0px" }
            }
          >
            <div
              className="filter-box"
              style={
                activeMenu ? { width: "calc(100% - 120px)" } : { width: "100%" }
              }
            >
              <Button
                type="primary"
                icon={<FaFilter />}
                onClick={() => setIsModalOpen(true)}
              >
                &nbsp; Filtrar
              </Button>

              {NPSFilter.length > 0 && (
                <div className="item">
                  <span>NPS</span>
                  <div className="filtros">{NPSFilter.join(", ")}</div>
                </div>
              )}

              {UnidadeFilter.length > 0 && (
                <div className="item">
                  <span>UNIDAD</span>
                  <div className="filtros">{UnidadeFilter.join(", ")}</div>
                </div>
              )}

              {FaixaEtariaFilter.length > 0 && (
                <div className="item">
                  <span>GRUPO DE EDAD</span>
                  <div className="filtros">{FaixaEtariaFilter.join(", ")}</div>
                </div>
              )}

              <Modal
                title="Filtrar"
                open={isModalOpen}
                onOk={() => {
                  setIsModalOpen(false);
                  doFilter();
                }}
                onCancel={() => setIsModalOpen(false)}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="nps-filter">NPS</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="nps-filter"
                    options={itemsNPS}
                    value={NPSFilter}
                    onChange={(newValue) => {
                      setNPSFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="unidade-filter">Unidad</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="unidade-filter"
                    options={itemsUnidade}
                    value={UnidadeFilter}
                    onChange={(newValue) => {
                      setUnidadeFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="faixa-filter">Grupo de Edad</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="faixa-filter"
                    options={itemsFaixa}
                    value={FaixaEtariaFilter}
                    onChange={(newValue) => {
                      setFaixaEtariaFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
              </Modal>
            </div>
            {tab === "#nps" && (
              <>
                <Row>
                  <Col span={12}>
                    <CardKPI
                      label="Respuestas"
                      value={dataJSON["responsesCount"]}
                      icon="users"
                      bgColor="#002A4E"
                    />
                  </Col>
                  <Col span={12}>
                    <CardKPI
                      label="NPS"
                      value={dataJSON["npsNumber"]}
                      icon="circles"
                      bgColor="#FFC400"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      id="nps-dist"
                      style={{ margin: 10, height: "300px" }}
                      title="NPS Distribución"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      De 0 a 10, ¿Cuánto recomendarías a Wizard
                                      para algún familiar o amigo(a)?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "nps",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <NPS data={dataJSON["npsDist"]} language="espanhol" />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="NPS Historico"
                    >
                      <LineChart
                        style={{ height: "210px" }}
                        data={dataJSON["npsHistoric"]}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Es alumno Wizard"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: <>¿Eres alumno(a) Wizard? </>,
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <PieChart
                        style={{ height: "200px" }}
                        data={dataJSON["É aluno"]}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "350px" }}
                      title="NPS x Unidad"
                      styles={{ body: { padding: 0, height: "290px" } }}
                    >
                      <BarChart
                        className="bar-chart-inovyo"
                        style={{ height: "220px" }}
                        data={dataJSON["NPSxRespondentes"]}
                        design="relationalBarYellow"
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#aluno" && (
              <>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Edad Alumno"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      ¿Cuál es tu edad?
                                      <br />
                                      ¿Cuál es la edad de él/ella?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Idade Aluno"]}
                        style={{ height: "210px" }}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Idioma que estudia"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      ¿Qué idioma estudias en Wizard?
                                      <br />
                                      ¿Qué idioma estudia él/ella en Wizard?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Idioma que estuda"]}
                        style={{ height: "210px" }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Modalidad"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      ¿Cuál es tu modalidad de clases en Wizard?
                                      <br />
                                      <br />
                                      ¿Cuál es la modalidad de clase de su hijo
                                      en Wizard?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <PieChart
                        style={{ height: "170px" }}
                        data={dataJSON["Modalidade"]}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Tiempo de Escuela"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      ¿Desde cuándo estudias en nuestra escuela?
                                      <br />
                                      <br />
                                      ¿Desde cuándo él/ella estudia en nuestra
                                      escuela?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Tempo de Escola"]}
                        style={{ height: "220px" }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Libro/Nivel que esta"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      ¿En qué libro/nivel estás?
                                      <br />
                                      ¿En qué libro/nivel de inglés estás?
                                      <br />
                                      <br />
                                      ¿En qué libro/nivel está su hijo/a?
                                      <br />
                                      ¿En qué libro/nivel de inglés está su
                                      hijo/a?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Livro/Nível que está"]}
                        style={{ height: "220px" }}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#tools" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfecho Material Didactico"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      En una escala de cero a diez, ¿cuán
                                      satisfecho se encuentra con el material
                                      didáctico que está utilizando en este
                                      momento?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATCombo
                        data={dataJSON["Satisfação Material Didático"]}
                        positions="0-10"
                        language="espanhol"
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" },
                      }}
                      title="Lo que necesita mejorar del Material Didactico"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      ¿Qué falta para que el material didáctico
                                      de Wizard sea mejor?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart
                        data={
                          dataJSON["O que precisa melhorar Material Didático"]
                        }
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="WORDCLOUD | Necesita mejorar Material Didáctico"
                      styles={{
                        body: { padding: "0px" },
                      }}
                    >
                      <WorldCloud
                        data={
                          dataJSON[
                            "WORDCLOUD | O que precisa melhorar Material Didático"
                          ]
                        }
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Herramientas que utilizo"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      ¿Qué herramientas descritas abajo utilizas
                                      o ya has utilizado al menos una vez?
                                      <br />
                                      <br />
                                      ¿Qué herramientas descritas abajo su
                                      hijo/hija utiliza o ha utilizado al menos
                                      una vez?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Ferramentas que usou"]}
                        style={{ height: "210px" }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfecho com Herramientas"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      ¿Cuál es su grado de satisfacción con las
                                      herramientas que figuran a continuación en
                                      lo que respecta a su contribución con el
                                      su aprendizaje?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATLines
                        data={dataJSON["Satisfação com Ferramentas"]}
                        positions="0-10"
                        language="espanhol"
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" },
                      }}
                      title="Lo que necesita mejorar de las Herramientas de la Wizard"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      ¿Qué hace falta para que las herramientas
                                      de Wizard sean mejores?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart
                        data={
                          dataJSON["Precisa melhorar Ferramentas da Wizard"]
                        }
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" },
                      }}
                      title="WORDCLOUD - Necesita mejorar herramientas Wizard"
                    >
                      <WorldCloud
                        data={
                          dataJSON[
                            "WORDCLOUD | Precisa melhorar Ferramentas da Wizard"
                          ]
                        }
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#teacher" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfecho com Clase"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Pensando en la experiencia de las clases,
                                      ¿cuánto concuerdas con las frases abajo?
                                      <br />
                                      <br />
                                      Pensando en la experiencia de su
                                      hijo/hija, ¿cuánto concuerda usted con las
                                      frases abajo?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATLines
                        data={dataJSON["Satisfação com Aulas"]}
                        language="espanhol"
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Evaluación Profesor"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Pensando en tu profesor(a), ¿cuánto
                                      concuerdas con las afirmaciones abajo?
                                      <br />
                                      <br />
                                      Pensando en el profesor de su hijo/hija,
                                      ¿cuánto concuerda usted con las
                                      afirmaciones abajo?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATLines
                        data={dataJSON["Avaliação Professor"]}
                        language="espanhol"
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#respondentes" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card style={{ margin: 10 }} styles={{ body: { padding: 5 } }}>
                      <Button
                        value={"Descarga la base"}
                        type="primary"
                        size="small"
                        onClick={() => setIsModalExcelOpen(true)}
                      >
                        Descarga la base
                      </Button>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card style={{ margin: 10 }}>
                      <TableChart
                        data={dataJSON["Respondentes"]}
                        design="respondentes"
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            <Modal
              title="Descarga la base"
              open={isModalExcelOpen}
              onOk={() => {
                setIsModalExcelOpen(false);
                setHasFile("");
              }}
              okText="Cerrar"
              closable={false}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              {hasFile === "" && (
                <div id="link-excel" className="process">
                  <RiFileDownloadFill /> Espera.. extrayendo datos...
                </div>
              )}

              {hasFile !== "" && hasFile !== "error" && (
                <a
                  href={hasFile}
                  target="_blank"
                  rel="noreferrer"
                  id="link-excel"
                >
                  <RiFileExcel2Fill /> Descargar Archivo
                </a>
              )}

              {hasFile !== "" && hasFile === "error" && (
                <div id="link-excel" className="error">
                  <RiCloseCircleFill /> ¡Error al descargar el archivo!
                </div>
              )}
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default PearsonWizardEspanhol;
