import {
  FaSmile,
  FaChartBar,
  FaChartArea,
  FaChartPie,
  FaChartLine,
  FaPaperPlane,
  FaHandshake,
  FaList,
  FaComments,
  FaUser,
  FaChalkboard,
  FaSchool,
  FaConciergeBell
} from "react-icons/fa";

export const TabIcons = () => {
  return [
    { name: "smile", icon: <FaSmile /> },
    { name: "chartbar", icon: <FaChartBar /> },
    { name: "chartpie", icon: <FaChartPie /> },
    { name: "chartarea", icon: <FaChartArea /> },
    { name: "chartline", icon: <FaChartLine /> },
    { name: "paperplane", icon: <FaPaperPlane /> },
    { name: "handshake", icon: <FaHandshake /> },
    { name: "list", icon: <FaList /> },
    { name: "comments", icon: <FaComments /> },
    { name: "user", icon: <FaUser /> },
    { name: "chalkboard", icon: <FaChalkboard /> },
    { name: "school", icon: <FaSchool /> },
    { name: "conciergebell", icon: <FaConciergeBell /> },
  ];
};
