import React, { useEffect } from "react";
import {
  FaSmile,
  FaUser,
  FaToolbox,
  FaChalkboardTeacher,
  FaList,
  FaChartLine,
  FaHome,
  FaBook
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

//redux
import {
  menuOpen,
  setTabActive,
  setMenuOpen,
} from "../../../store/dashboard/dashboard.slice";

//style
import "../../../assets/css/dashboard-light/menu/main-menu.css";

const MainMenuComponent = ({ items, design }) => {
  const dispatch = useDispatch();
  const menuOpenLocal = useSelector(menuOpen);

  const listIcons = {
    smile: <FaSmile />,
    user: <FaUser />,
    toolbox: <FaToolbox />,
    "chalkboard-teacher": <FaChalkboardTeacher />,
    list: <FaList />,
    chartLine: <FaChartLine />,
    home: <FaHome />,
    book: <FaBook />,
  };

  const checkWidthDocument = () => {
    const docW = document.documentElement.clientWidth;
    let fun = null;
    if (docW <= 960) {
      fun = setTimeout(() => {
        clearTimeout(fun);
        dispatch(setMenuOpen(false));
      }, 200);
    } else {
      fun = setTimeout(() => {
        clearTimeout(fun);
        dispatch(setMenuOpen(true));
      }, 200);
    }
  };

  window.addEventListener("resize", (w) => {
    checkWidthDocument();
  });

  useEffect(() => {
    checkWidthDocument();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {menuOpenLocal && (
        <div id="i-menu">
          <div className={`i-menu-content ${design}`}>
            {items &&
              items.length > 0 &&
              items.map((itemMenu, index) => (
                <a
                  href={itemMenu.href}
                  key={"main-menu-" + index}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setTabActive(itemMenu.href));
                    window.scrollTo(0, 0);
                  }}
                >
                  {listIcons[itemMenu.icon]}
                  <span>{itemMenu.label}</span>
                </a>
              ))}
          </div>
          <div className="i-menu-logo">
            <img
              src="https://inovyo-logos.s3.sa-east-1.amazonaws.com/inovyo/logo_xm_vertical.png"
              alt="Inovyo"
              className="logo-inovyo"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MainMenuComponent;
