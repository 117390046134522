import { Table, Card, Col, Row, Tooltip, Popconfirm, Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const AccessPage = () => {
  const dataSource = [
    {
      key: "name",
      id: "1",
      name: "Administrador",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Deletar",
      dataIndex: "del",
      key: "del",
      render: (_, row) => {
        return (
          <Tooltip title="Excluir Grupo de acesso">
            <Popconfirm
              title={`Excluir o grupo de acesso ${row.name}`}
              okText="Sim"
              cancelText="Não"
              placement="bottomRight"
              onConfirm={() => {}}
            >
              <Button
                shape="circle"
                value="small"
                icon={<DeleteOutlined style={{ color: "#9A2376" }} />}
              />
            </Popconfirm>
          </Tooltip>
        );
      },
    },
    {
      title: "Editar",
      dataIndex: "edit",
      key: "edit",
      render: (_, row) => {
        return (
          <Tooltip title="Editar Grupo de acesso">
            <Popconfirm
              title={`Editar o grupo de acesso ${row.name}`}
              okText="Sim"
              cancelText="Não"
              placement="bottomRight"
              onConfirm={() => {}}
            >
              <Button
                shape="circle"
                value="small"
                icon={<EditOutlined style={{ color: "#9A2376" }} />}
              />
            </Popconfirm>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Row>
      <Col span={24} style={{ padding: "10px" }}>
        <Card title="Grupos de Acesso">
          <Table dataSource={dataSource} columns={columns} />
        </Card>
      </Col>
    </Row>
  );
};

export default AccessPage;
