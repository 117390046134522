import React from "react";
import { Layout } from "antd";

//styles
import "../../assets/css/app/content-app.css";

const ContentApp = ({ children }) => {
  const { Content } = Layout;

  return (
    <Content className={`content-app`} id="content-app">
      {children}
    </Content>
  );
};

export default ContentApp;
