import React, { useState, useEffect } from "react";
import { Modal, Card } from "antd";
import { useSelector, useDispatch } from "react-redux";

//redux
import {
  infoModal,
  setInfoModal,
} from "../../../store/dashboard/dashboard.slice";

//images
import NPSOrientation from "../../../assets/images/nps-orientation.png";

const ModalDash = () => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const infoModalItem = useSelector(infoModal);

  const dafaultMessages = {
    nps: {
      title: "O que é NPS",
      content: (
        <p>
          Altas pontuações sobre esta questão estão fortemente correlacionadas
          com recompras, indicações e outras ações que contribuam para o
          crescimento de uma empresa.
          <br />
          <br />
          <img src={NPSOrientation} alt="NPSOrientation" width={"100%"} />
        </p>
      ),
    },
    csat: {
      title: "O que é CSAT?",
      content: (
        <p>CSAT = Numero de clientes Satisfeitos / Número de Respondentes</p>
      ),
    },
  }[infoModalItem && infoModalItem.type] || {
    title: "Pergunta no questionário",
    content: infoModalItem !== null ? infoModalItem.content : null,
  };

  useEffect(() => {
    setIsModalOpen(infoModalItem !== null);
  }, [infoModalItem]);

  return (
    <Modal
      open={isModalOpen}
      onOk={() => {
        dispatch(setInfoModal(null));
      }}
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }}
      styles={{
        body: { margin: 0, padding: "0px" }
      }}
      style={{ padding: 0 }}
    >
      {infoModalItem !== null && (
        <Card
          title={dafaultMessages.title}
          bordered={false}
          style={{ boxShadow: "none", padding: 0 }}
        >
          {dafaultMessages.content}
        </Card>
      )}
    </Modal>
  );
};

export default ModalDash;
