import api from "../../service/api";

export const handleLoginAPI = async (credentials) => {
  return api
    .post("/login2", credentials)
    .then((reponse) => {
      return reponse.data;
    });
};

export const handleLoginLightAPI = async (credentials) => {
  return api
    .post("https://backpearson.inovyo.com/api/login", credentials)
    .then((reponse) => {
      return reponse.data;
    });
};
