/**
 * Gera o title de cada página passando os dados via array
 * @param {Array} arrayTitle - Array com os titles da página
 */
export const setPageTitle = (arrayTitle) => {
  let baseTitle = ["Inovyo Experience Management"];
  arrayTitle.forEach((title) => {
    baseTitle.push(title);
  });
  document.title =
    baseTitle.length > 1 ? baseTitle.reverse().join(" | ") : baseTitle[0];
};

/**
 * Descobre se um hexadecimal é escuro ou claro, com base na sua luminosidade
 * @param {string} hexadecimal - (String) #000000
 * @returns {string}
 */
export const getLuminosity = (hexadecimal) => {
  var nib = hexadecimal.split("");
  var r = parseInt(nib[1] + nib[2], 16);
  var g = parseInt(nib[3] + nib[4], 16);
  var b = parseInt(nib[5] + nib[6], 16);
  var luminosity = (r * 299 + g * 587 + b * 114) / 1000;
  return luminosity < 127.5 ? "dark" : "light";
};
