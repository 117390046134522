import React from "react";
import { Popover } from "antd";

//styles
import "../../../../assets/css/dashboard-light/charts/csat-lines.css";

const CSATLines = (props) => {
  const { data: dataJson, positions, language } = props;

  const csatDesc = {
    espanhol: {
      media: "Media",
      respostas: "Respuestas",
    },
  }[language] || {
    media: "Média",
    respostas: "Respostas",
  };

  const csatDataItems = Object.keys(dataJson.csat);

  const getAvg = (dimension) => {
    var avg = 0;
    const itemsDimension = Object.values(dataJson.csat[dimension]);
    const keysDimension = Object.keys(dataJson.csat[dimension]);

    var totalRespostas = itemsDimension.reduce((soma, i) => soma + i, 0);

    keysDimension.forEach((key, i) => {
      if (key !== "N/A") {
        avg += key * itemsDimension[i];
      }
    });
    return (avg / totalRespostas).toFixed(1);
  };

  const getTotal = (dimension) => {
    const itemsDimension = Object.values(dataJson.csat[dimension]);
    var totalRespostas = itemsDimension.reduce((soma, i) => soma + i, 0);
    return totalRespostas;
  };

  const GenerateHorizontalLines = ({ dimension }) => {
    const itemsDimension = Object.values(dataJson.csat[dimension]);
    const keysDimension = Object.keys(dataJson.csat[dimension]);
    var totalRespostas = itemsDimension.reduce((soma, i) => soma + i, 0);

    if (positions === "0-10") {
      let agrupamaneto = {};

      keysDimension.forEach((a) => {
        const originalA = a !== "N/A" ? parseInt(a) : a;
        a = ["0", "1", "2", "3", "4", "5", "6"].includes(a) ? "0-6" : a;

        if (agrupamaneto[a]) {
          agrupamaneto[a] += dataJson.csat[dimension][originalA];
        } else {
          agrupamaneto[a] = dataJson.csat[dimension][originalA];
        }
      });

      let keysAgrupamentoBase = ["0-6", "7", "8", "9", "10", "N/A"];
      let keysAgrupamento = [];

      keysAgrupamentoBase.forEach((keyMap) => {
        if (Object.keys(agrupamaneto).includes(keyMap)) {
          keysAgrupamento.push(keyMap);
        }
      });

      const horizontalBars = keysAgrupamento.map((hl, i) => {
        const calcWithBar = (agrupamaneto[hl] / totalRespostas) * 100;

        const classColor =
          keysAgrupamento[i] === "N/A" ? "bar-na" : `bar-${keysAgrupamento[i]}`;

        return (
          <Popover
            content={
              <p>
                {parseInt(calcWithBar)}%: {new Intl.NumberFormat("pt-BR").format(hl)}{" "}
                {csatDesc.respostas.toLowerCase()}
              </p>
            }
            title={`NOTA: ${keysDimension[i]}`}
            key={"pop-csat" + Math.random()}
          >
            <div
              className={classColor}
              key={"bar-csat" + Math.random()}
              style={{ width: calcWithBar + "%" }}
            >
              {parseInt(calcWithBar)}%
            </div>
          </Popover>
        );
      });

      return (
        <div className="box-horizontal-csat">
          <div className="content">{horizontalBars}</div>
        </div>
      );
    } else {
      const horizontalBars = itemsDimension.map((hl, i) => {
        const calcWithBar = (hl / totalRespostas) * 100;

        const classColor =
          keysDimension[i] === "N/A" ? "bar-na" : `bar-${keysDimension[i]}`;

        return (
          <Popover
            content={
              <p>
                {parseInt(calcWithBar)}%: {new Intl.NumberFormat("pt-BR").format(hl)}{" "}
                {csatDesc.respostas.toLowerCase()}
              </p>
            }
            title={`NOTA: ${keysDimension[i]}`}
            key={"pop-csat" + Math.random()}
          >
            <div
              className={classColor}
              key={"line-csat" + Math.random()}
              style={{ width: calcWithBar + "%" }}
            >
              {parseInt(calcWithBar)}%
            </div>
          </Popover>
        );
      });

      return (
        <div className="box-horizontal-csat">
          <div className="content">{horizontalBars}</div>
        </div>
      );
    }
  };

  return (
    <div id="csat-lines-inovyo">
      {csatDataItems.map((csatDimension) => {
        return (
          <div className="line-data" key={"csat-line-" + Math.random()}>
            <div className="titles">{csatDimension}</div>
            <div className="avg">
              {csatDesc.media}: {getAvg(csatDimension)}
            </div>
            <div className="total">
              {csatDesc.respostas}: {new Intl.NumberFormat("pt-BR").format(getTotal(csatDimension))}
            </div>
            <div className="bar">
              <GenerateHorizontalLines dimension={csatDimension} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CSATLines;
