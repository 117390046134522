/**
 * Cria um storage se ele não existir. O padrão é vazio, mas
 * ele pode receber um valor padrão desde que seja informado
 * @param {string} nameStorage
 * @param {string} defaultValue
 */
export const createStorageIfNotExist = (nameStorage, defaultValue) => {
  const storeFilter = localStorage.getItem(nameStorage);
  if (storeFilter === null) {
    localStorage.setItem(nameStorage, defaultValue !== "" ? defaultValue : "");
  }
};

/**
 * Cria um storage
 * @param {string} name
 * @param {string} value
 */
export const setStorage = (name, value) => {
  localStorage.setItem(name, value);
};

/**
 * Pega o valor de um storage e retorna se ele existir
 * @param {string} name
 * @returns string
 */
export const getStorage = (name) => {
  const storage = localStorage.getItem(name);
  return storage !== null ? localStorage.getItem(name) : "";
};

/**
 * Exclui um storage específico
 * @param {string} name
 */
export const deleteStorage = (name) => {
  localStorage.removeItem(name);
};

/**
 * Apaga todos os storages do navegador
 */
export const clearStorages = () => {
  localStorage.clear();
};
