import { setResponse, setTabActive } from "./dashboard.slice";
import { setLoading } from "../application/app.slice";
import { setStorage } from "../../utils/Storage";
import dataJson from "../../pages/Dashboard/data.json";

export const getDataDashboard = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setResponse(dataJson));
    dispatch(setTabActive(dataJson.content[0].contentId));
    setStorage("DASH", JSON.stringify(dataJson));

    setTimeout(() => {
      dispatch(setLoading(false));
    }, 2000);
  };
};
