import React from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Loading = (props) => {
  const { full } = props;
  return (
    <>
      {full ? (
        <div className="loading-full">
          <Spin indicator={<LoadingOutlined spin />} />
        </div>
      ) : (
        <div className="loading">
          <Spin indicator={<LoadingOutlined spin />} />
        </div>
      )}
    </>
  );
};

export default Loading;
