import React, { useState } from "react";
import { FaInfoCircle, FaPlusCircle } from "react-icons/fa";
import { Modal } from "antd";

//styles
import "../../../assets/css/dashboard/card/card.css";

const Card = (props) => {
  let { title, width, info } = props.data;

  const [modalOpen, setModalOpen] = useState(false);

  width = width || 6;
  title = title || "";
  info = info || "";

  const infoMessage = () => {
    Modal.info({
      title: "Pergunta no questionário",
      content: (
        <div>
          <p>{info}</p>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <div className={width !== "" ? `col-sm-${width}` : `col`}>
      <div id="card-default" className="card">
        {title !== "" && (
          <div className="card-header">
            <strong>{title}</strong>
            {info !== "" && (
              <div className="card-box-buttons">
                <div
                  className="card-header-btn"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  <FaPlusCircle />
                </div>
                <div className="card-header-btn" onClick={() => infoMessage()}>
                  <FaInfoCircle />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="card-body">Gráfico vai vir aqui</div>
      </div>
      <Modal
        title="Outros"
        visible={modalOpen}
        onOk={() => setModalOpen(!modalOpen)}
        onCancel={() => setModalOpen(!modalOpen)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>Gráfico de outros vem aqui</p>
      </Modal>
    </div>
  );
};

export default Card;
