import React, { useState, useEffect } from "react";
import ReactWordcloud from "react-wordcloud";

const WordCloudChart = (props) => {
  let { data: dataJson } = props;
  let { data: dataWords } = dataJson;

  const [words, setWords] = useState(null);

  useEffect(() => {
    let wordsBreakedObj = {};
    let wordsBreaked = [];

    dataWords.forEach((w) => {
      const splited = w.split(" ");
      splited.forEach((s) => {
        if (!wordsBreakedObj[s]) {
          wordsBreakedObj[s] = 1;
        } else {
          wordsBreakedObj[s] += 1;
        }
      });
    });

    Object.keys(wordsBreakedObj).forEach((wb) => {
      wordsBreaked.push({
        text: wb,
        value: wordsBreakedObj[wb],
      });
    });

    setWords(wordsBreaked);
    // eslint-disable-next-line
  }, []);

  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
  };

  return (
    <>
      <div
        style={{
          height: 250,
          width: "100%",
          textAlign: "center",
          maxwidth: "600px",
        }}
      >
        <ReactWordcloud maxwidth={600} options={options} words={words} />
      </div>
    </>
  );
};

export default WordCloudChart;
