import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "integration",
  initialState: {
    historicBases: [],
    tableLoading: false,
  },
  reducers: {
    setHistoricBases(state, { payload }) {
      return { ...state, historicBases: payload };
    },
    setTableLoading(state, { payload }) {
      return { ...state, tableLoading: payload };
    },
  },
});

export const { setHistoricBases, setTableLoading } = slice.actions;

export const historicBases = (state) => state.integration.historicBases;
export const tableLoading = (state) => state.integration.tableLoading;

export default slice.reducer;
