import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";

const PieChart = (props) => {
  let { data: dataJson, design } = props;

  //const checkBarValue = (value) => value === "white";
  const [setting, setSetting] = useState(null);

  let settings = {
    default: {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "right",
          },
          tooltip: {
            callbacks: {
              title: function(tooltipItem) {
                const originalLabel = Object.keys(dataJson.data)[tooltipItem[0]['dataIndex']];
                return `${originalLabel}`;
              },
              label: function(tooltipItem) {
                const originalLabel = Object.keys(dataJson.data)[tooltipItem['dataIndex']];
                return `${new Intl.NumberFormat('pt-BR').format(dataJson.data[originalLabel])}`;
              }
            }
          },
          datalabels: {
            formatter: (value) => new Intl.NumberFormat("pt-BR").format(value)
          }
        },
      },
      data: {
        datasets: [
          {
            datalabels: {
              labels: {
                index: {
                  display: true,
                  color: "#000",
                  clamp: true,
                  font: {
                    size: 12,
                  },
                  align: "end",
                  anchor: "end",
                },
                value: {
                  display: true,
                  offset: 20,
                  color: "white",
                  clamp: true,
                  align: "start",
                  anchor: "end",
                  textStrokeWidth: 3,
                  textStrokeColor: "#222",
                },
              },
            },
            backgroundColor: [
              "#9A2376",
              "#FFC400",
              "#008D3F",
              "#00A3DD",
              "#FF1928",
              "#8E0A14",
              "#593959",
              "#1BBF37",
              "#F64827",
              "#77429D",
              "#FFA300",
            ],
            hoverOffset: 0,
          },
        ],
      },
    },
  };

  useEffect(() => {
    const setDesign = settings[design] ? settings[design] : settings["default"];

    setDesign.data.labels = Object.keys(dataJson.data);
    setDesign.data.datasets[0].data = Object.values(dataJson.data);

    if (setDesign.data.datasets[0].datalabels.labels.index) {
      setDesign.data.datasets[0].datalabels.labels.index.formatter = (
        val,
        ctx
      ) => setDesign.data.labels[ctx.dataIndex];
    }

    setSetting(setDesign);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {setting !== null && (
        <Pie
          id={`pie-chart-${Math.random()}`}
          {...props}
          data={setting.data}
          options={setting.options}
        />
      )}
    </>
  );
};

export default PieChart;
