import React, { useState, useEffect } from "react";
import GaugeChart from "react-gauge-chart";

const styles = {
  dial: {
    display: "inline-block",
    width: `300px`,
    color: "#000",
    border: "0.5px solid #fff",
    padding: "50px 2px",
  },
  title: {
    fontSize: "1em",
    color: "#000",
    width: "100%",
    textAlign: "center",
  },
};

let settings = {
  default: {
    nrOfLevels: 1,
    arcsLength: [0.2, 0.2, 0.2, 0.2, 0.2],
    colors: ["#4EC04E", "#ACC75D", "#FFC84A", "#FA8A48", "#EB4841"],
    arcPadding: 0.01,
    textColor: "#000",
    needleColor: "#888",
  },
};

const AccelerationChart = (props) => {
  let { data, design } = props;
  const { impact } = data;

  const [setting, setSetting] = useState(null);

  useEffect(() => {
    let setDesign = settings[design] ? settings[design] : settings["default"];
    setSetting(setDesign);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {setting !== null && (
        <div style={styles.dial}>
          <GaugeChart
            {...setting}
            percent={impact.value}
            formatTextValue={(value) => value + "%"}
          />
          <div style={styles.title}>{impact.label}</div>
        </div>
      )}
    </>
  );
};

export default AccelerationChart;
