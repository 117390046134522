import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "login",
  initialState: {
    response: null,
  },
  reducers: {
    setLoginResponse(state, { payload }) {
      return { ...state, response: payload };
    },
  },
});

export const { setLoginResponse } = slice.actions;

export const loginResponse = (state) => state.login.response;

export default slice.reducer;
