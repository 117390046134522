import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Input, Button } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { v4 } from "uuid";
import {
  FaSmile,
  FaChartBar,
  FaChartArea,
  FaChartPie,
  FaChartLine,
  FaPaperPlane,
  FaHandshake,
  FaList,
  FaComments,
  FaUser,
  FaChalkboard,
  FaSchool,
  FaConciergeBell,
  FaRegEdit,
  FaWindowClose,
} from "react-icons/fa";

//redux
import {
  setTabActive,
  setResponse,
  menuOpen,
  edition,
  tabActive,
  response,
} from "../../../store/dashboard/dashboard.slice";

//styles
import "../../../assets/css/dashboard/menu/tabs-menu.css";

//utils
import { setStorage, getStorage } from "../../../utils/Storage";
import { TabIcons } from "../../../utils/Icons";

const TabsMenu = () => {
  const menuOpenRedux = useSelector(menuOpen);
  const editionRedux = useSelector(edition);
  const tabActiveRedux = useSelector(tabActive);
  const responseRedux = useSelector(response);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalIconsOpen, setModalIconsOpen] = useState(false);
  const [seletedIcon, setSeletedIcon] = useState(0);
  const dispatch = useDispatch();

  const listIcons = {
    smile: <FaSmile />,
    chartbar: <FaChartBar />,
    chartpie: <FaChartPie />,
    chartarea: <FaChartArea />,
    chartline: <FaChartLine />,
    paperplane: <FaPaperPlane />,
    handshake: <FaHandshake />,
    list: <FaList />,
    comments: <FaComments />,
    user: <FaUser />,
    chalkboard: <FaChalkboard />,
    school: <FaSchool />,
    conciergebell: <FaConciergeBell />,
  };

  const updateTabName = (item, index) => {
    const { value } = item.target;
    let newDash = JSON.parse(getStorage("DASH"));
    newDash.content.map((obj, i) => {
      if (i === index) {
        obj.tab.name = value;
      }
      return obj;
    });
    dispatch(setResponse(newDash));
    setStorage("DASH", JSON.stringify(newDash));
  };

  const updateTabIcon = (iconName, index) => {
    let newDash = JSON.parse(getStorage("DASH"));
    const newContent = newDash.content.map((obj, i) => {
      if (i === index) {
        obj.tab.icon = iconName;
      }
      return obj;
    });
    newDash.content = newContent;
    dispatch(setResponse(newDash));
    setStorage("DASH", JSON.stringify(newDash));
  };

  const updateTabColor = (el) => {
    let { value: color } = el.target;
    let newDash = JSON.parse(getStorage("DASH"));
    newDash.menuColor = color;
    dispatch(setResponse(newDash));
    setStorage("DASH", JSON.stringify(newDash));
  };

  const deleteContentTab = (contentId) => {
    let newDash = JSON.parse(getStorage("DASH"));
    let newContent = [];
    newDash.content.forEach((obj) => {
      if (obj.contentId !== contentId) {
        newContent.push(obj);
      }
    });
    newDash.content = newContent;
    dispatch(setResponse(newDash));
    setStorage("DASH", JSON.stringify(newDash));
  };

  const addNewPage = () => {
    let newDash = JSON.parse(getStorage("DASH"));
    newDash.content.push({
      contentId: v4(),
      tab: {
        icon: "smile",
        name: "PÁGINA",
      },
      rows: [],
    });
    dispatch(setResponse(newDash));
    setStorage("DASH", JSON.stringify(newDash));
  };

  return (
    <>
      <div
        id="tabs-menu"
        className={`tabs-menu ${menuOpenRedux ? "active" : "inactive"}`}
      >
        <div className="tabs-items">
          {responseRedux !== null &&
            responseRedux.content.map((itemMenu) => {
              return (
                <div
                  className={`tabs-menu-item ${
                    tabActiveRedux === itemMenu.contentId ? "marked" : ""
                  }`}
                  id={itemMenu.contentId}
                  key={itemMenu.contentId}
                  onClick={() => {
                    dispatch(setTabActive(itemMenu.contentId));
                  }}
                >
                  {listIcons[itemMenu.tab.icon]}
                  <br />
                  {itemMenu.tab.name}
                </div>
              );
            })}

          {editionRedux && (
            <>
              <div
                className="add-new-page"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <span>
                  Editar
                  <br />
                  <FaRegEdit />
                </span>
              </div>
              <Modal
                title="Ediçã do Menu"
                visible={modalOpen}
                onOk={() => setModalOpen(!modalOpen)}
                onCancel={() => setModalOpen(!modalOpen)}
                cancelButtonProps={{ style: { display: "none" } }}
                styles={{
                  body: { padding: "0px" }
                }}
              >
                <div id="modal-tab-menu">
                  <h6>Cor do menu</h6>
                  <div className="color">
                    <input
                      type="color"
                      id="updateTabColor"
                      name="updateTabColor"
                      value={responseRedux.menuColor}
                      onChange={(e) => updateTabColor(e)}
                    />
                  </div>
                  <h6>Páginas</h6>
                  {responseRedux !== null && (
                    <>
                      {responseRedux.content.map((itemModal, index) => {
                        return (
                          <div
                            key={"im" + itemModal.dashId + index}
                            className="modal-tab-menu-items"
                          >
                            <div
                              className="icon"
                              onClick={() => {
                                setModalIconsOpen(!modalIconsOpen);
                                setSeletedIcon(index);
                              }}
                            >
                              {listIcons[itemModal.tab.icon]}
                            </div>
                            <div className="name">
                              <Input
                                value={itemModal.tab.name}
                                onChange={(e) => updateTabName(e, index)}
                              />
                            </div>
                            <div
                              className="delete"
                              onClick={() =>
                                deleteContentTab(itemModal.contentId)
                              }
                            >
                              <FaWindowClose />
                            </div>
                          </div>
                        );
                      })}
                      <div className="add-page">
                        <Button
                          type="primary"
                          icon={<PlusCircleFilled />}
                          onClick={() => addNewPage()}
                        >
                          Nova Página
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Modal>
              <Modal
                title="Ícones"
                visible={modalIconsOpen}
                onOk={() => setModalIconsOpen(!modalIconsOpen)}
                onCancel={() => setModalIconsOpen(!modalIconsOpen)}
                cancelButtonProps={{ style: { display: "none" } }}
                styles={{
                  body: { padding: "0px" }
                }}
              >
                <div id="modal-tab-menu-icons">
                  {TabIcons().map((symbol, ind) => {
                    return (
                      <div
                        className="item"
                        key={"icon" + ind}
                        onClick={() => {
                          updateTabIcon(symbol.name, seletedIcon);
                          setModalIconsOpen(!modalIconsOpen);
                        }}
                      >
                        {symbol.icon}
                      </div>
                    );
                  })}
                </div>
              </Modal>
            </>
          )}
        </div>
        <div className="logo-menu">
          <img
            src="https://inovyo-logos.s3.sa-east-1.amazonaws.com/inovyo/logo_xm_vertical.png"
            alt="Inovyo"
          ></img>
        </div>
        {responseRedux !== null &&
          responseRedux.menuColor &&
          responseRedux.menuColor !== "" && (
            <style>{`
              #tabs-menu {
                color: ${responseRedux.menuColor};
              }
        
              #tabs-menu > .tabs-items::-webkit-scrollbar-thumb {
                background-color: ${responseRedux.menuColor};
              }
        
              #tabs-menu > .tabs-items > .tabs-menu-item.marked {
                background-color: ${responseRedux.menuColor};
              }
        
              #tabs-menu > .tabs-items > .tabs-menu-item:hover {
                background-color: ${responseRedux.menuColor};
              }
              
              #tabs-menu > .tabs-items > .tabs-menu-item > svg {
                color: ${responseRedux.menuColor};
              }
          `}</style>
          )}
      </div>
    </>
  );
};

export default TabsMenu;
