import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "app",
  initialState: {
    loading: false,
    bodyWidth: document.body.clientWidth
  },
  reducers: {
    setLoading(state, { payload }) {
      return { ...state, loading: payload };
    },
    setBodyWidth(state, { payload }) {
      return { ...state, bodyWidth: payload };
    },
  },
});

export const { setLoading, setBodyWidth } = slice.actions;

export const loading = (state) => state.app.loading;
export const bodyWidth = (state) => state.app.bodyWidth;

export default slice.reducer;
