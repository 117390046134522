import React, { useState, useEffect, useContext } from "react";
import { Space, Button } from "antd";
import { Col, Row, Card, Select, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FaFilter, FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import {
  RiFileExcel2Fill,
  RiCloseCircleFill,
  RiFileDownloadFill,
} from "react-icons/ri";

import api from "../../../service/api";

//components
import MainMenu from "../Menu";
import { CardKPI } from "../Card";

//charts
import LineChart from "../Charts/Line";
import PieChart from "../Charts/Pie";
import BarChart from "../Charts/Bar";
import NPS from "../Charts/NPS";
import CSATCombo from "../Charts/CSATCombo";
import CSATLines from "../Charts/CSATLines";
import TableChart from "../Charts/Table";
import WorldCloud from "../Charts/WorldCloud";

//data
//import dataJSON from "../data.json";

//redux
import {
  tabActive,
  menuOpen,
  setResponseLight,
  responseLight,
  setInfoModal,
} from "../../../store/dashboard/dashboard.slice";

//providers
import { Context } from "../../../providers/AuthProvider";

const PearsonWizard = () => {
  const { dashid } = useParams();
  let { token } = useContext(Context);

  const tab = useSelector(tabActive);
  const activeMenu = useSelector(menuOpen);
  const dataJSON = useSelector(responseLight);
  const dispatch = useDispatch();

  const [localLoad, setLocalLoad] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExcelOpen, setIsModalExcelOpen] = useState(false);
  const [hasFile, setHasFile] = useState("");

  const [NPSFilter, setNPSFilter] = useState([]);
  const [UnidadeFilter, setUnidadeFilter] = useState([]);
  const [FaixaEtariaFilter, setFaixaEtariaFilter] = useState([]);

  //dados dos filtros
  const [itemsNPS, setItemsNPS] = useState([]);
  const [itemsUnidade, setItemsUnidade] = useState([]);
  const [itemsFaixa, setItemsFaixa] = useState([]);

  const getInitData = () => {
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, [], {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));

        //setando filtros
        //nps
        let filter0 = data.filter.NPS.map((u) => {
          return { value: u, label: u };
        });
        setItemsNPS(filter0);
        //unidade
        let filter1 = data.filter.Unidade.map((u) => {
          return { value: u, label: u };
        });
        setItemsUnidade(filter1);
        //faixa
        let filter4 = data.filter["Idade Aluno"].map((u) => {
          return { value: u, label: u };
        });
        setItemsFaixa(filter4);
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const doFilter = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    dispatch(setResponseLight(null));
    setLocalLoad(true);
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, filterBase, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const getExcel = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    api
      .post(
        "https://backpearson.inovyo.com/api/download/" + dashid,
        filterBase,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((result) => {
        const { data } = result;
        if (data.status) {
          setHasFile(data.file);
        } else {
          setHasFile("error");
        }
      })
      .catch(() => {
        setHasFile("error");
      });
  };

  useEffect(() => {
    getInitData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isModalExcelOpen) {
      setTimeout(() => {
        getExcel();
      }, 1500);
    }
    // eslint-disable-next-line
  }, [isModalExcelOpen]);

  return (
    <>
      {localLoad && dataJSON === null && (
        <div className="loading-data">
          <span>Carregando dados...</span> <br />
          Isto pode levar alguns segundos!
        </div>
      )}
      {!localLoad && dataJSON === null && (
        <div className="loading-error">
          <span>X</span> <br />
          Erro ao carregar dados!
        </div>
      )}
      {!localLoad && dataJSON !== null && (
        <>
          <MainMenu
            design="pw"
            items={[
              {
                icon: "smile",
                label: "NPS",
                href: "#nps",
              },
              {
                icon: "user",
                label: "Aluno",
                href: "#aluno",
              },
              {
                icon: "toolbox",
                label: "Material / Ferramentas",
                href: "#tools",
              },
              {
                icon: "chalkboard-teacher",
                label: "Professor",
                href: "#teacher",
              },
              {
                icon: "list",
                label: "Respondentes",
                href: "#respondentes",
              },
            ]}
          />
          <div
            id="dahboard-light"
            style={
              activeMenu
                ? { width: "calc(100% - 120px)", marginLeft: "120px" }
                : { width: "100%", marginLeft: "0px" }
            }
          >
            <div
              className="filter-box"
              style={
                activeMenu ? { width: "calc(100% - 120px)" } : { width: "100%" }
              }
            >
              <Button
                type="primary"
                icon={<FaFilter />}
                onClick={() => setIsModalOpen(true)}
              >
                &nbsp; Filtrar
              </Button>

              {NPSFilter.length > 0 && (
                <div className="item">
                  <span>NPS</span>
                  <div className="filtros">{NPSFilter.join(", ")}</div>
                </div>
              )}

              {UnidadeFilter.length > 0 && (
                <div className="item">
                  <span>UNIDADE</span>
                  <div className="filtros">{UnidadeFilter.join(", ")}</div>
                </div>
              )}

              {FaixaEtariaFilter.length > 0 && (
                <div className="item">
                  <span>FAIXA ETÁRIA</span>
                  <div className="filtros">{FaixaEtariaFilter.join(", ")}</div>
                </div>
              )}

              <Modal
                title="Filtrar"
                open={isModalOpen}
                onOk={() => {
                  setIsModalOpen(false);
                  doFilter();
                }}
                onCancel={() => setIsModalOpen(false)}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="nps-filter">NPS</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="nps-filter"
                    options={itemsNPS}
                    value={NPSFilter}
                    onChange={(newValue) => {
                      setNPSFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="unidade-filter">Unidade</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="unidade-filter"
                    options={itemsUnidade}
                    value={UnidadeFilter}
                    onChange={(newValue) => {
                      setUnidadeFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="faixa-filter">Faixa Etária</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="faixa-filter"
                    options={itemsFaixa}
                    value={FaixaEtariaFilter}
                    onChange={(newValue) => {
                      setFaixaEtariaFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
              </Modal>
            </div>
            {tab === "#nps" && (
              <>
                <Row>
                  <Col span={12}>
                    <CardKPI
                      label="Respostas"
                      value={dataJSON["responsesCount"]}
                      icon="users"
                      bgColor="#002A4E"
                    />
                  </Col>
                  <Col span={12}>
                    <CardKPI
                      label="NPS"
                      value={dataJSON["npsNumber"]}
                      icon="circles"
                      bgColor="#FFC400"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      id="nps-dist"
                      style={{ margin: 10, height: "300px" }}
                      title="NPS Distribuição"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content:
                                    "De 0 a 10, o quanto você recomendaria a Wizard para algum familiar ou amigo?",
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "nps",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <NPS data={dataJSON["npsDist"]} />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="NPS Histórico"
                    >
                      <LineChart
                        style={{ height: "210px" }}
                        data={dataJSON["npsHistoric"]}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="É aluno Wizard"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: <>Você é aluno(a) Wizard?</>,
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <PieChart
                        style={{ height: "200px" }}
                        data={dataJSON["É aluno"]}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "350px" }}
                      title="NPS x Unidade"
                      styles={{ body: { padding: 0, height: "290px" } }}
                    >
                      <BarChart
                        className="bar-chart-inovyo"
                        style={{ height: "220px" }}
                        data={dataJSON["NPSxRespondentes"]}
                        design="relationalBarYellow"
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#aluno" && (
              <>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Idade Aluno"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Qual a idade dele/dela?
                                      <br />
                                      Qual a sua idade?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Idade Aluno"]}
                        style={{ height: "210px" }}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Idioma que estuda"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Agora que conhecemos um pouquinho sobre
                                      você, queremos saber sobre o seu curso na
                                      Wizard! Qual idioma você estuda na Wizard?
                                      Para o caso de estudar mais de um idioma,
                                      escolha o mais relevante nesse momento.
                                      <br />
                                      Qual idioma ele(a) estuda na Wizard? Para
                                      o caso de estudar mais de um idioma,
                                      escolha o mais relevante nesse momento.
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Idioma que estuda"]}
                        style={{ height: "210px" }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Modalidade"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Qual é a sua modalidade?
                                      <br />
                                      <br />
                                      Qual é a modalidade do seu filho de aulas
                                      na Wizard?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <PieChart
                        style={{ height: "170px" }}
                        data={dataJSON["Modalidade"]}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Tempo de Escola"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Há quanto tempo você estuda nessa escola?
                                      <br />
                                      <br />
                                      Há quanto tempo ele(a) estuda nessa
                                      escola?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Tempo de Escola"]}
                        style={{ height: "220px" }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Livro/Nível que está"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Em que livro/nível você está?
                                      <br />
                                      Em que livro/nível do inglês você está?
                                      <br />
                                      <br />
                                      Em que livro/nível o seu filho/a sua filha
                                      está?
                                      <br />
                                      Em que livro/nível do inglês o seu filho/a
                                      sua filha está?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Livro/Nível que está"]}
                        style={{ height: "220px" }}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#tools" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfação Material Didático"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Numa escala de zero a dez, o quão
                                      satisfeito você está com o material
                                      didático que está sendo utilizado nesse
                                      momento?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATCombo
                        data={dataJSON["Satisfação Material Didático"]}
                        positions="0-10"
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" }
                      }}
                      title="O que precisa melhorar Material Didático"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      O que falta para que o material didático
                                      da Wizard seja melhor?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart
                        data={
                          dataJSON["O que precisa melhorar Material Didático"]
                        }
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="WORDCLOUD | O que precisa melhorar Material Didático"
                      styles={{
                        body: { padding: "0px" }
                      }}
                    >
                      <WorldCloud
                        data={
                          dataJSON[
                            "WORDCLOUD | O que precisa melhorar Material Didático"
                          ]
                        }
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Ferramentas que usou"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Quais das ferramentas abaixo você utiliza
                                      ou já utilizou pelo menos uma vez?
                                      <br />
                                      <br />
                                      Quais das ferramentas abaixo seu filho(a)
                                      utiliza ou já utilizou pelo menos uma vez?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Ferramentas que usou"]}
                        style={{ height: "210px" }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfação com Ferramentas"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      O quanto você está satisfeito com as
                                      ferramentas abaixo pensando na
                                      contribuição delas para o seu aprendizado?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATLines
                        data={dataJSON["Satisfação com Ferramentas"]}
                        positions="0-10"
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" }
                      }}
                      title="Precisa melhorar Ferramentas da Wizard"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      O que falta para que as ferramentas da
                                      Wizard sejam melhores?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart
                        data={
                          dataJSON["Precisa melhorar Ferramentas da Wizard"]
                        }
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" }
                      }}
                      title="WORDCLOUD | Precisa melhorar Ferramentas da Wizard"
                    >
                      <WorldCloud
                        data={
                          dataJSON[
                            "WORDCLOUD | Precisa melhorar Ferramentas da Wizard"
                          ]
                        }
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#teacher" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfação com Aulas"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Pensando em sua experiência nas aulas, o
                                      quanto você concorda com as frases abaixo?
                                      <br />
                                      <br />
                                      Pensando na experiência do seu filho/ da
                                      sua filha nas aulas, o quanto você
                                      concorda com as frases abaixo?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATLines data={dataJSON["Satisfação com Aulas"]} />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Avaliação Professor"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Pensando no seu(sua) professor(a), o
                                      quanto você concorda com as afirmativas
                                      abaixo?
                                      <br />
                                      <br />
                                      Pensando no professor(a) do seu filho, o
                                      quanto você concorda com as afirmativas
                                      abaixo?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATLines data={dataJSON["Avaliação Professor"]} />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#respondentes" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card style={{ margin: 10 }} styles={{ body: { padding: 5 } }}>
                      <Button
                        value={"Download de Base"}
                        type="primary"
                        size="small"
                        onClick={() => setIsModalExcelOpen(true)}
                      >
                        Download de Base
                      </Button>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card style={{ margin: 10 }}>
                      <TableChart
                        data={dataJSON["Respondentes"]}
                        design="respondentes"
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            <Modal
              title="Download de Base"
              open={isModalExcelOpen}
              onOk={() => {
                setIsModalExcelOpen(false);
                setHasFile("");
              }}
              okText="Fechar"
              closable={false}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              {hasFile === "" && (
                <div id="link-excel" className="process">
                  <RiFileDownloadFill /> Aguarde.. extraindo dados...
                </div>
              )}

              {hasFile !== "" && hasFile !== "error" && (
                <a
                  href={hasFile}
                  target="_blank"
                  rel="noreferrer"
                  id="link-excel"
                >
                  <RiFileExcel2Fill /> Baixar Arquivo
                </a>
              )}

              {hasFile !== "" && hasFile === "error" && (
                <div id="link-excel" className="error">
                  <RiCloseCircleFill /> Falha ao baixar arquivo!
                </div>
              )}
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default PearsonWizard;
