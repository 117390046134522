import React from "react";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";

//styles
import '../../assets/css/app/loading.css'

const Loading = () => {
  const loading = useSelector((state) => state.app.loading);
  return (
    <>
      {loading && (
        <div className="loading-ui">
          <ReactLoading
            type={"spin"}
            color={"#9a2376"}
            height={50}
            width={50}
          />
        </div>
      )}
    </>
  );
};

export default Loading;
