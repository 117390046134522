import React, { useState, useEffect, useContext } from "react";
import { Space, Button } from "antd";
import { Col, Row, Card, Select, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FaFilter, FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import {
  RiFileExcel2Fill,
  RiCloseCircleFill,
  RiFileDownloadFill,
} from "react-icons/ri";

import api from "../../../service/api";

//components
import MainMenu from "../Menu";
import { CardKPI } from "../Card";

//charts
import LineChart from "../Charts/Line";
import PieChart from "../Charts/Pie";
import BarChart from "../Charts/Bar";
import NPS from "../Charts/NPS";
import CSATCombo from "../Charts/CSATCombo";
import CSATLines from "../Charts/CSATLines";
import TableChart from "../Charts/Table";
//import WorldCloud from "../Charts/WorldCloud";

//redux
import {
  tabActive,
  menuOpen,
  setResponseLight,
  responseLight,
  setInfoModal,
} from "../../../store/dashboard/dashboard.slice";

//providers
import { Context } from "../../../providers/AuthProvider";

const PearsonYazigi = () => {
  const { dashid } = useParams();
  let { token } = useContext(Context);

  const tab = useSelector(tabActive);
  const activeMenu = useSelector(menuOpen);
  const dataJSON = useSelector(responseLight);
  const dispatch = useDispatch();

  const [localLoad, setLocalLoad] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExcelOpen, setIsModalExcelOpen] = useState(false);
  const [hasFile, setHasFile] = useState("");

  const [AnoFilter, setAnoFilter] = useState([]);
  const [NPSFilter, setNPSFilter] = useState([]);
  const [UnidadeFilter, setUnidadeFilter] = useState([]);
  const [FaixaEtariaFilter, setFaixaEtariaFilter] = useState([]);

  //dados dos filtros
  const [itemsAno, setItemsAno] = useState([]);
  const [itemsNPS, setItemsNPS] = useState([]);
  const [itemsUnidade, setItemsUnidade] = useState([]);
  const [itemsFaixa, setItemsFaixa] = useState([]);

  const getInitData = () => {
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, [], {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));

        //setando filtros
        //nps
        let filter0 = data.filter.NPS.map((u) => {
          return { value: u, label: u };
        });
        setItemsNPS(filter0);
        setNPSFilter(filter0.map((objeto) => objeto.label));
        //unidade
        let filter1 = data.filter.Unidade.map((u) => {
          return { value: u, label: u };
        });
        setItemsUnidade(filter1);
        setUnidadeFilter(filter1.map((objeto) => objeto.label));
        //faixa
        let filter4 = data.filter["Idade Aluno"].map((u) => {
          return { value: u, label: u };
        });
        setItemsFaixa(filter4);
        //ano
        let filter5 = data.filter.Ano.map((u) => {
          return { value: u, label: u };
        });
        setItemsAno(filter5);
        setAnoFilter(filter5.map((objeto) => objeto.label));
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const doFilter = () => {
    let filterBase = [
      { Ano: AnoFilter },
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    dispatch(setResponseLight(null));
    setLocalLoad(true);
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, filterBase, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const getExcel = () => {
    let filterBase = [
      { Ano: AnoFilter },
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    api
      .post(
        "https://backpearson.inovyo.com/api/download/" + dashid,
        filterBase,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((result) => {
        const { data } = result;
        if (data.status) {
          setHasFile(data.file);
        } else {
          setHasFile("error");
        }
      })
      .catch(() => {
        setHasFile("error");
      });
  };

  useEffect(() => {
    getInitData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isModalExcelOpen) {
      setTimeout(() => {
        getExcel();
      }, 1500);
    }
    // eslint-disable-next-line
  }, [isModalExcelOpen]);

  const BuildKPIs = ({ dataKPI, kpiTitle }) => {
    const { csat, impacto, media } = dataKPI.kpi;

    return (
      <Row>
        <Col span={24}>
          <Card
            styles={{
              body: { padding: "0px" },
            }}
            style={{ margin: 10, padding: 0 }}
            className="i-kpi-card"
          >
            <em>{kpiTitle}</em>
            <ul>
              <li>
                <strong>Média CSAT</strong>
                <span>{media}</span>
              </li>
              <li>
                <strong>CSAT Score</strong>
                <span>{csat * 100}%</span>
              </li>
              <li>
                <strong>Impacto no NPS</strong>
                <span>{impacto * 100}%</span>
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {localLoad && dataJSON === null && (
        <div className="loading-data">
          <span>Carregando dados...</span> <br />
          Isto pode levar alguns segundos!
        </div>
      )}
      {!localLoad && dataJSON === null && (
        <div className="loading-error">
          <span>X</span> <br />
          Erro ao carregar dados!
        </div>
      )}
      {!localLoad && dataJSON !== null && (
        <>
          <MainMenu
            design="py"
            items={[
              {
                icon: "smile",
                label: "NPS",
                href: "#nps",
              },
              {
                icon: "user",
                label: "Aluno",
                href: "#aluno",
              },
              {
                icon: "toolbox",
                label: "Material / Escola",
                href: "#tools",
              },
              {
                icon: "chartLine",
                label: "Avaliações",
                href: "#csat",
              },
              {
                icon: "chalkboard-teacher",
                label: "Retenção Aluno",
                href: "#teacher",
              },
              {
                icon: "list",
                label: "Respondentes",
                href: "#respondentes",
              },
            ]}
          />
          <div
            id="dahboard-light"
            style={
              activeMenu
                ? { width: "calc(100% - 120px)", marginLeft: "120px" }
                : { width: "100%", marginLeft: "0px" }
            }
          >
            <div
              className="filter-box"
              style={
                activeMenu ? { width: "calc(100% - 120px)" } : { width: "100%" }
              }
            >
              <Button
                type="primary"
                icon={<FaFilter />}
                onClick={() => setIsModalOpen(true)}
              >
                &nbsp; Filtrar
              </Button>

              {AnoFilter.length > 0 && (
                <div className="item">
                  <span>Ano</span>
                  <div className="filtros">{AnoFilter.join(", ")}</div>
                </div>
              )}

              {NPSFilter.length > 0 && (
                <div className="item">
                  <span>NPS</span>
                  <div className="filtros">{NPSFilter.join(", ")}</div>
                </div>
              )}

              {UnidadeFilter.length > 0 && (
                <div className="item">
                  <span>UNIDADE</span>
                  <div className="filtros">{UnidadeFilter.join(", ")}</div>
                </div>
              )}

              {FaixaEtariaFilter.length > 0 && (
                <div className="item">
                  <span>FAIXA ETÁRIA</span>
                  <div className="filtros">{FaixaEtariaFilter.join(", ")}</div>
                </div>
              )}

              <Modal
                title="Filtrar"
                open={isModalOpen}
                onOk={() => {
                  setIsModalOpen(false);
                  doFilter();
                }}
                onCancel={() => setIsModalOpen(false)}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="ano-filter">ANO</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="ano-filter"
                    options={itemsAno}
                    value={AnoFilter}
                    onChange={(newValue) => {
                      setAnoFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="nps-filter">NPS</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="nps-filter"
                    options={itemsNPS}
                    value={NPSFilter}
                    onChange={(newValue) => {
                      setNPSFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="unidade-filter">Unidade</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="unidade-filter"
                    options={itemsUnidade}
                    value={UnidadeFilter}
                    onChange={(newValue) => {
                      setUnidadeFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="faixa-filter">Faixa Etária</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="faixa-filter"
                    options={itemsFaixa}
                    value={FaixaEtariaFilter}
                    onChange={(newValue) => {
                      setFaixaEtariaFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
              </Modal>
            </div>
            {tab === "#nps" && (
              <>
                <Row>
                  <Col span={12}>
                    <CardKPI
                      label="Respostas"
                      value={dataJSON["responsesCount"]}
                      icon="users"
                      bgColor="#5b338b"
                    />
                  </Col>
                  <Col span={12}>
                    <CardKPI
                      label="NPS"
                      value={dataJSON["npsNumber"]}
                      icon="circles"
                      bgColor="#FFC400"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      id="nps-dist"
                      style={{ margin: 10, height: "300px" }}
                      title="NPS Distribuição"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content:
                                    "De 0 a 10, o quanto você recomendaria o Yázigi para algum familiar ou amigo?",
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "nps",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <NPS data={dataJSON["npsDist"]} />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="NPS Histórico"
                    >
                      <LineChart
                        style={{ height: "210px" }}
                        data={dataJSON["npsHistoric"]}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" },
                      }}
                      title="Precisa melhorar no Yázigi"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content:
                                    "O que falta para que o Yázigi seja melhor?",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart
                        data={dataJSON["Precisa melhorar na Yázigi"]}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" },
                      }}
                      title="Por que recomenda o Yázigi?"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content:
                                    "Ficamos muito felizes com seu feedback! Quais são as principais razões pelas quais você recomendaria o Yázigi?",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart data={dataJSON["Por que recomenda Yázigi"]} />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="É aluno Yázigi"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: "Você é aluno(a) Yázigi?",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <PieChart
                        style={{ height: "200px" }}
                        data={dataJSON["É aluno"]}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "350px" }}
                      title="NPS x Unidade"
                      styles={{ body: { padding: 0, height: "290px" } }}
                    >
                      <BarChart
                        className="bar-chart-inovyo"
                        style={{ height: "220px" }}
                        data={dataJSON["NPSxRespondentes"]}
                        design="relationalBarYellow"
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#aluno" && (
              <>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Idade Aluno"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <p>
                                      Qual a sua idade?
                                      <br />
                                      Qual a idade dele/dela?
                                    </p>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Idade Aluno"]}
                        style={{ height: "210px" }}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Idioma que estuda"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <p>
                                      Qual idioma você estuda no Yázigi!?
                                      <br />
                                      Qual idioma ele(a) estuda no Yázigi?
                                    </p>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Idioma que estuda"]}
                        style={{ height: "210px" }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Modalidade"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <p>
                                      Qual é a sua modalidade na aulas no
                                      Yázigi?
                                      <br />
                                      Qual é a modalidade do seu filho de aulas
                                      no Yázigi?
                                    </p>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <PieChart
                        style={{ height: "170px" }}
                        data={dataJSON["Modalidade"]}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Tempo de Escola"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <p>
                                      Há quanto tempo você estuda nessa escola?
                                      <br />
                                      Há quanto tempo ele(a) estuda nessa
                                      escola?
                                    </p>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Tempo de Escola"]}
                        style={{ height: "220px" }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Livro/Nível que está"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <p>
                                      Em que livro/nível você está?
                                      <br />
                                      Em que livro/nível do inglês você está?
                                      <br />
                                      <br />
                                      Em que livro/nível o seu filho/a sua filha
                                      está?
                                      <br />
                                      Em que livro/nível do inglês o seu filho/a
                                      sua filha está?
                                    </p>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Livro/Nível que está"]}
                        style={{ height: "220px" }}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#tools" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfação Material Didático"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      O quanto você considera que o material
                                      didático do Yázigi te auxilia no
                                      aprendizado do idioma?
                                      <br />
                                      <br />O quanto você considera que o
                                      material didático do Yázigi auxilia no
                                      aprendizado do idioma do seu filho/ da sua
                                      filha?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATCombo
                        data={dataJSON["Satisfação Material Didático"]}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfação com o Yconnect"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      O quanto você acha que o Yconnect
                                      contribui para o seu aprendizado?
                                      <br />
                                      <br />O quanto você acha que o Yconnect
                                      contribui para o aprendizado dele/ dela?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATCombo data={dataJSON["Satisfação com o Yconnect"]} />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfação com aprendizado em sala"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Na sua percepção, o quanto suas aulas no
                                      Yázigi estão contribuindo para que você
                                      realmente aprenda o idioma?
                                      <br />
                                      <br />
                                      Na sua percepção, o quanto as aulas no
                                      Yázigi estão contribuindo para que
                                      seu(sua) filho(a) realmente aprenda o
                                      idioma?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATCombo
                        data={dataJSON["Satisfação com aprendizado em sala"]}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Satisfação com nível de comunicação da área pedagógica"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Como você avalia o nível de comunicação da
                                      área pedagógica da escola Yázigi com você?
                                      <br />
                                      <br />
                                      <strong>5: Excelente -</strong> A
                                      comunicação dos professores e/ou
                                      coordenação pedagógica conosco é ótima
                                      <br />
                                      <strong>4: Boa -</strong> A comunicação
                                      dos professores e/ou coordenação
                                      pedagógica conosco nos atende bem
                                      <br />
                                      <strong>3: Neutra -</strong>A comunicação
                                      dos professores e/ou coordenação
                                      pedagógica conosco é mais ou menos
                                      <br />
                                      <strong>2: Ruim -</strong> A comunicação
                                      dos professores e/ou coordenação
                                      pedagógica conosco deixa a desejar
                                      <br />
                                      <strong>1: Péssima -</strong> A
                                      comunicação dos professores e/ou
                                      coordenação pedagógica conosco precisa
                                      melhorar com urgência
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATCombo
                        data={
                          dataJSON[
                            "Satisfação com nível de comunicação da área pedagógica"
                          ]
                        }
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#csat" && (
              <>
                <BuildKPIs
                  kpiTitle="Avaliações sobre Escola"
                  dataKPI={dataJSON["Avaliações sobre Escola"]}
                />
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Avaliações sobre Escola"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Agora queremos entender um pouco mais
                                      sobre as suas aulas e sobre a sua
                                      percepção da unidade onde estuda.
                                      <br />
                                      Agora queremos entender um pouco mais
                                      sobre como estão as aulas do seu filho/da
                                      sua filha e sobre a unidade onde ele(a)
                                      estuda.
                                      <br />
                                      <br />
                                      Pensando na escola, como você avalia?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATLines data={dataJSON["Avaliações sobre Escola"]} />
                    </Card>
                  </Col>
                </Row>
                <BuildKPIs
                  kpiTitle="Avaliações sobre Professor(a)"
                  dataKPI={dataJSON["Avaliações sobre Professor(a)"]}
                />
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      title="Avaliações sobre Professor(a)"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Pensando na sua experiência de aulas, você
                                      pode afirmar que seu professor(a)?
                                      <br />
                                      <br />
                                      Pensando na experiência de aulas do seu
                                      filho/da sua filha, você pode afirmar que
                                      o professor(a)?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <CSATLines
                        data={dataJSON["Avaliações sobre Professor(a)"]}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#teacher" && (
              <>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Continuará no próximo semestre"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Você pretende manter seu filho/ sua filha
                                      matriculado no Yázigi no próximo semestre?
                                      <br />
                                      <br />
                                      Você pretende continuar estudando no
                                      Yázigi no próximo semestre?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <PieChart
                        style={{ height: "170px" }}
                        data={dataJSON["Continuará no próximo semestre"]}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Motivo de não querer continuar como aluno do Yázigi"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Você pretende manter seu filho/ sua filha
                                      matriculado no Yázigi no próximo semestre?
                                      <br />
                                      <br />
                                      Você pretende continuar estudando no
                                      Yázigi no próximo semestre?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Motivo de não querer ser aluno"]}
                        style={{ height: "220px" }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" },
                      }}
                      title="O que pode ser feito para manter o aluno?"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      O que poderíamos fazer para seu(sua)
                                      filho(a) continuar estudando conosco?
                                      <br />
                                      <br />O que poderíamos fazer para você
                                      continuar estudando conosco?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart
                        data={dataJSON["Pode ser feito para manter aluno"]}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" },
                      }}
                      title="Escola/solução para a qual o aluno pretende migrar"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Para qual escola de idiomas seu
                                      filho/filha consideraria ir?
                                      <br />
                                      <br />
                                      Para qual escola de idiomas você
                                      consideraria ir?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart
                        data={dataJSON["Escola que aluno pretende migrar"]}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Aceitaria alguma condição especial para continuar no YZG?"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Havendo uma condição especial para você
                                      iniciar ou voltar a estudar inglês, você
                                      tem interesse?
                                      <br />
                                      <br />
                                      Havendo uma condição especial para você
                                      iniciar ou voltar a estudar inglês, você
                                      tem interesse?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <PieChart
                        style={{ height: "170px" }}
                        data={dataJSON["Tem interesse em voltar"]}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
                        body: { padding: "0px" },
                      }}
                      title="Nome Responsável"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: <>Qual o seu nome completo?</>,
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart data={dataJSON["Nome Responsável"]} />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      title="Nível Idioma do Responsável"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      Agora, gostaríamos de falar diretamente
                                      com você, pai/mãe/responsável. Qual seu
                                      nível de conhecimento na língua inglesa?
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <BarChart
                        data={dataJSON["Nível Idioma do Responsável"]}
                        style={{ height: "220px" }}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {tab === "#respondentes" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      styles={{ body: { padding: 5 } }}
                    >
                      <Button
                        value={"Download de Base"}
                        type="primary"
                        size="small"
                        onClick={() => setIsModalExcelOpen(true)}
                      >
                        Download de Base
                      </Button>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card style={{ margin: 10 }}>
                      <TableChart
                        data={dataJSON["Respondentes"]}
                        design="respondentes"
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            <Modal
              title="Download de Base"
              open={isModalExcelOpen}
              onOk={() => {
                setIsModalExcelOpen(false);
                setHasFile("");
              }}
              okText="Fechar"
              closable={false}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              {hasFile === "" && (
                <div id="link-excel" className="process">
                  <RiFileDownloadFill /> Aguarde.. extraindo dados...
                </div>
              )}

              {hasFile !== "" && hasFile !== "error" && (
                <a
                  href={hasFile}
                  target="_blank"
                  rel="noreferrer"
                  id="link-excel"
                >
                  <RiFileExcel2Fill /> Baixar Arquivo
                </a>
              )}

              {hasFile !== "" && hasFile === "error" && (
                <div id="link-excel" className="error">
                  <RiCloseCircleFill /> Falha ao baixar arquivo!
                </div>
              )}
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default PearsonYazigi;
