import React from "react";

const NotPagePage = () => {
  return (
    <div>
      <h1>Página não encontrada!</h1>
    </div>
  );
};

export default NotPagePage;
