import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "antd";
import {
  FaUsers,
  FaBullseye,
  // FaFilter,
  FaInfoCircle,
  // FaQuestionCircle,
  FaExpand,
} from "react-icons/fa";
//utils
import { getLuminosity } from "../../../utils/Strings";

//styles
import "../../../assets/css/dashboard-light/card/card.css";

//redux
import {
  setInfoModal,
  isModalVisible,
  setIsModalVisible,
  chartType,
  setChartType,
  chartData,
  setChartData,
  setFullScreenTitle,
} from "../../../store/dashboard/dashboard.slice";

//charts
import LineChart from "../Charts/Line";
import PieChart from "../Charts/Pie";
import BarChart from "../Charts/Bar";
import NPS from "../Charts/NPS";
import CSATCombo from "../Charts/CSATCombo";
import CSATLines from "../Charts/CSATLines";
import TableChart from "../Charts/Table";
import WorldCloud from "../Charts/WorldCloud";

export const CardKPI = ({ icon, value, label, bgColor }) => {
  const iconsList = {
    circles: <FaBullseye />,
    users: <FaUsers />,
  };

  const textColor = getLuminosity(bgColor) === "dark" ? "#fff" : "#00";

  const styleCard = {
    backgroundColor: bgColor,
    padding: 0,
    borderRadius: 8,
    color: textColor,
  };

  return (
    <Card id="i-card" styles={{ body: styleCard }}>
      <div className="title">{label}</div>
      <div className="value">
        {new Intl.NumberFormat("pt-BR").format(value)}
      </div>
      <div className="icon">{iconsList[icon]}</div>
    </Card>
  );
};

export const CreateElement = ({ type, chart }) => {
  const chartTypeRedux = useSelector(chartType);
  const chartDataRedux = useSelector(chartData);
  const fullScreenMode = useSelector(isModalVisible);
  
  type = type || chartTypeRedux;
  chart = chart || chartDataRedux;

  let chartEdit = {...chart}

  if (fullScreenMode) {
    chartEdit.style = {
      ...chartEdit.style,
      height: `${window.innerHeight - 84}px`
    }
  }

  

  let chartProp;

  switch (type) {
    case "NPS":
      return <NPS {...chartEdit} />;

    case "LineChart":
      return <LineChart {...chartEdit} />;

    case "TableChart":
      return <TableChart {...chartEdit} />;

    case "WorldCloud":
      return <WorldCloud {...chartEdit} />;

    case "PieChart":
      chartProp = {...chartEdit}
      chartProp.style = chartProp.style || { height: "200px" }
      return <PieChart {...chartProp} />;

    case "BarChart":
      chartProp = {...chartEdit}
      chartProp.style = chartProp.style || { height: "210px" }
      return <BarChart {...chartProp} />;

    case "CSATCombo":
      return <CSATCombo {...chartEdit} />;

    case "CSATLines":
      return <CSATLines {...chartEdit} />;

    default:
      break;
  }
};

export const CardChart = ({ ...rest }) => {
  rest.question = rest.question || "";
  let fullScreenMode = rest.chart.fullscreen===false ? false : true;

  const dispatch = useDispatch();

  const createExtra = ({ ...rest }) => {
    fullScreenMode = false;
    if (rest.question === "" && !rest.extra && !fullScreenMode) {
      return false
    }

    return (
      <>
        {rest.question !== "" ? (
          <FaInfoCircle
            style={{ cursor: "pointer", marginLeft: "10px" }}
            title="Informativo"
            onClick={() =>
              dispatch(
                setInfoModal({
                  type: "question",
                  content: rest.question,
                })
              )
            }
          />
        ) : (
          <></>
        )}
        {rest.extra ? (
          rest.extra
        ) : (
          <></>
        )}
        {fullScreenMode ? (
          <FaExpand
            style={{ cursor: "pointer", marginLeft: "10px" }}
            onClick={() => {
              dispatch(setFullScreenTitle(rest.title));
              
              dispatch(setChartType(rest.type));
              dispatch(setChartData(rest.chart));

              dispatch(setIsModalVisible(true));
            }}
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  let chartProp;

  switch (rest.type) {
    case "WorldCloud":
      chartProp = {...rest};
      chartProp.styles = chartProp.styles || { body: { padding: "0px" } };
      chartProp.style = chartProp.style || { margin: 10, height: "300px" };
      break;

    case "TableChart":
      chartProp = {...rest};
      chartProp.styles = chartProp.styles || { body: { padding: "0px" } };
      chartProp.style = chartProp.style || { margin: 10, height: "300px" };
      break;

    case "CSATLines":
      chartProp = {...rest};
      chartProp.style = chartProp.style || { margin: 10 };
      break;

    case "CSATCombo":
      chartProp = {...rest};
      chartProp.style = chartProp.style || { margin: 10 };
      break;

    case "BarChart":
      chartProp = {...rest};
      chartProp.style = chartProp.style || { margin: 10, height: "300px" };
      break;

    default:
      chartProp = {...rest   }
      chartProp.style = chartProp.style || { margin: 10, height: "300px" };
      break;
  }

  const extraCard = createExtra(chartProp);
  if (extraCard !== false) {
    chartProp.extra = extraCard;
  }

  return (
    <Card {...chartProp} >
      <CreateElement type={chartProp.type} chart={chartProp.chart} />
    </Card>
  );
};
