import React from "react";
import { createRoot } from "react-dom/client";

//antd
import ptBR from "antd/es/locale/pt_BR";
import { ConfigProvider } from "antd";

//redux
import { Provider } from "react-redux";
import store from "./store";

//app
import App from "./App";
import { AuthProvider } from "./providers/AuthProvider";

const root = createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider
    locale={ptBR}
    theme={{ token: { colorPrimary: "#9A2376", linkColor: "#9A2376" } }}
  >
    <AuthProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  </ConfigProvider>
);
