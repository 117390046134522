import React, { useState, useEffect, useContext } from "react";
import { Space, Button } from "antd";
import { Col, Row, Card, Select, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FaFilter, FaQuestionCircle } from "react-icons/fa";
import {
  RiFileExcel2Fill,
  RiCloseCircleFill,
  RiFileDownloadFill,
} from "react-icons/ri";

import api from "../../../service/api";

//components
import MainMenu from "../Menu";
import { CardKPI, CardChart, CreateElement } from "../Card";

//data
//import dataJSON from "../data.json";

//redux
import {
  tabActive,
  menuOpen,
  setResponseLight,
  responseLight,
  setInfoModal,
  isModalVisible,
  fullScreenTitle,
  setIsModalVisible,
  setChartType,
  setChartData,
  setFullScreenTitle,
} from "../../../store/dashboard/dashboard.slice";

//providers
import { Context } from "../../../providers/AuthProvider";

const PearsonWizardEspanhol2024 = () => {
  const { dashid } = useParams();
  let { token } = useContext(Context);

  const tab = useSelector(tabActive);
  const activeMenu = useSelector(menuOpen);
  const dataJSON = useSelector(responseLight);
  const dispatch = useDispatch();

  const modalVisible = useSelector(isModalVisible);
  const titleFullScreen = useSelector(fullScreenTitle);

  const [localLoad, setLocalLoad] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExcelOpen, setIsModalExcelOpen] = useState(false);
  const [hasFile, setHasFile] = useState("");

  const [NPSFilter, setNPSFilter] = useState([]);
  const [UnidadeFilter, setUnidadeFilter] = useState([]);
  const [FaixaEtariaFilter, setFaixaEtariaFilter] = useState([]);

  //dados dos filtros
  const [itemsNPS, setItemsNPS] = useState([]);
  const [itemsUnidade, setItemsUnidade] = useState([]);
  const [itemsFaixa, setItemsFaixa] = useState([]);

  const getInitData = () => {
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, [], {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));

        //setando filtros
        //nps
        let filter0 = data.filter.NPS.map((u) => {
          return { value: u, label: u };
        });
        setItemsNPS(filter0);
        //unidade
        let filter1 = data.filter.Unidade.map((u) => {
          return { value: u, label: u };
        });
        setItemsUnidade(filter1);
        //faixa
        let filter4 = data.filter["Idade Aluno"].map((u) => {
          return { value: u, label: u };
        });
        setItemsFaixa(filter4);
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const doFilter = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    dispatch(setResponseLight(null));
    setLocalLoad(true);
    api
      .post("https://backpearson.inovyo.com/api/" + dashid, filterBase, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const getExcel = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    api
      .post("https://backpearson.inovyo.com/api/download/" + dashid, filterBase, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        if (data.status) {
          setHasFile(data.file);
        } else {
          setHasFile("error");
        }
      })
      .catch(() => {
        setHasFile("error");
      });
  };

  useEffect(() => {
    getInitData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isModalExcelOpen) {
      setTimeout(() => {
        getExcel();
      }, 1500);
    }
    // eslint-disable-next-line
  }, [isModalExcelOpen]);

  // const ContentFullScreen = () => {
  //   switch (chartType) {
  //     case "WorldCloud":
  //       return (
  //         <WorldCloud
  //           data={dataJSON[fullScreenTitle]}
  //           title={fullScreenTitle}
  //         />
  //       );
  //     case "BarChart":
  //       return (
  //         <BarChart
  //           data={dataJSON[fullScreenTitle]}
  //           style={{ height: "100%" }}
  //         />
  //       );
  //     case "PieChart":
  //       return <PieChart data={dataJSON[fullScreenTitle]} />;

  //     default:
  //       return <></>;
  //   }
  // };

  return (
    <>
      {localLoad && dataJSON === null && (
        <div className="loading-data">
          <span>Carregando dados...</span> <br />
          Isto pode levar alguns segundos!
        </div>
      )}
      {!localLoad && dataJSON === null && (
        <div className="loading-error">
          <span>X</span> <br />
          Erro ao carregar dados!
        </div>
      )}
      {!localLoad && dataJSON !== null && (
        <>
          <MainMenu
            design="pw"
            items={[
              {
                icon: "home",
                label: "General",
                href: "#nps",
              },
              {
                icon: "user",
                label: "Alumno",
                href: "#aluno",
              },
              {
                icon: "book",
                label: "Incorporación",
                href: "#onboarding",
              },
              {
                icon: "book",
                label: "Experiencia de Aprendizaje",
                href: "#aprendizado",
              },
              {
                icon: "book",
                label: "Reinscripción",
                href: "#rematricula",
              },
              {
                icon: "book",
                label: "Objetivos Alcanzados",
                href: "#objetivos",
              },
              {
                icon: "list",
                label: "Encuestados",
                href: "#respondentes",
              },
            ]}
          />
          <div
            id="dahboard-light"
            style={
              activeMenu
                ? { width: "calc(100% - 120px)", marginLeft: "120px" }
                : { width: "100%", marginLeft: "0px" }
            }
          >
            <div
              className="filter-box"
              style={
                activeMenu ? { width: "calc(100% - 120px)" } : { width: "100%" }
              }
            >
              <Button
                type="primary"
                icon={<FaFilter />}
                onClick={() => setIsModalOpen(true)}
              >
                &nbsp; Filtrar
              </Button>

              {NPSFilter.length > 0 && (
                <div className="item">
                  <span>NPS</span>
                  <div className="filtros">{NPSFilter.join(", ")}</div>
                </div>
              )}

              {UnidadeFilter.length > 0 && (
                <div className="item">
                  <span>UNIDAD</span>
                  <div className="filtros">{UnidadeFilter.join(", ")}</div>
                </div>
              )}

              {FaixaEtariaFilter.length > 0 && (
                <div className="item">
                  <span>GRUPO DE EDAD</span>
                  <div className="filtros">{FaixaEtariaFilter.join(", ")}</div>
                </div>
              )}

              <Modal
                title="Filtrar"
                open={isModalOpen}
                onOk={() => {
                  setIsModalOpen(false);
                  doFilter();
                }}
                onCancel={() => setIsModalOpen(false)}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="nps-filter">NPS</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="nps-filter"
                    options={itemsNPS}
                    value={NPSFilter}
                    onChange={(newValue) => {
                      setNPSFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="unidade-filter">Unidad</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="unidade-filter"
                    options={itemsUnidade}
                    value={UnidadeFilter}
                    onChange={(newValue) => {
                      setUnidadeFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <label htmlFor="faixa-filter">Grupo de Edad</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    id="faixa-filter"
                    options={itemsFaixa}
                    value={FaixaEtariaFilter}
                    onChange={(newValue) => {
                      setFaixaEtariaFilter(newValue);
                    }}
                    placeholder="Selecione.."
                    maxTagCount="responsive"
                  />
                </Space>
              </Modal>
            </div>
            {tab === "#nps" && (
              <>
                <Row>
                  <Col span={8}>
                    <CardKPI
                      label="Respuestas Total"
                      value={dataJSON["responsesCount"]}
                      icon="users"
                      bgColor="#002A4E"
                    />
                  </Col>
                  <Col span={8}>
                    <CardKPI
                      label="Respustas NPS"
                      value={dataJSON["npsCount"]}
                      icon="users"
                      bgColor="#002A4E"
                    />
                  </Col>
                  <Col span={8}>
                    <CardKPI
                      label="NPS Score"
                      value={dataJSON["npsNumber"]}
                      icon="circles"
                      bgColor="#FFC400"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      id="nps-dist"
                      type="NPS"
                      title="NPS Distribución"
                      question="De 0 a 10, o quanto você recomendaria a Wizard para algum familiar ou amigo?"
                      chart={{
                        data: dataJSON["npsDist"],
                        fullscreen: false,
                        language: "espanhol",
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="LineChart"
                      title="NPS Historico"
                      chart={{
                        data: dataJSON["npsHistoric"],
                        style: { height: "210px" },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Razones NPS - Promotor"
                      question={
                        <>
                          ¡Estamos muy contentos con tus comentarios! ¿Cuáles
                          son las principales razones por las que recomendarías
                          Wizard??
                        </>
                      }
                      chart={{
                        data: dataJSON["Motivo NPS - Promotor"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Razones NPS - Promotor"
                      chart={{
                        data: dataJSON["WORDCLOUD | Motivo NPS - Promotor"],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Razones NPS - Neutro y detractor"
                      question="¿Qué considera que hace falta para que Wizard sea mejor?"
                      chart={{
                        data: dataJSON["Motivo NPS - Neutro e detrator"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Razones NPS - Neutro y detractor"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Motivo NPS - Neutro e detrator"
                        ],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Es alumno Wizard"
                      question="¿Eres alumno(na) Wizard?"
                      chart={{
                        data: dataJSON["É aluno"],
                      }}
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="NPS x Unidade"
                      style={{ margin: 10, height: "350px" }}
                      styles={{ body: { padding: 0, height: "290px" } }}
                      chart={{
                        data: dataJSON["NPSxRespondentes"],
                        style: { height: "220px" },
                        className: "bar-chart-inovyo",
                        design: "relationalBarYellow"
                      }}
                    />
                  </Col>
                </Row> */}
              </>
            )}
            {tab === "#aluno" && (
              <>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Edad alumno"
                      question={
                        <>
                          ¿Cuál es tu edad?
                          <br />
                          <br />
                          ¿Cuál es la edad de él/ella?
                        </>
                      }
                      chart={{
                        data: dataJSON["Idade Aluno"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Idioma que estudia"
                      question={
                        <>
                          ¿Cuál idioma estudias en Wizard?En caso de estudiar
                          más de un idioma, elija el que sea más relevante en
                          este momento.
                          <br />
                          <br />
                          ¿Cuál idioma estudia el/ella en Wizard? En caso de
                          estudiar más de un idioma, elija el que sea más
                          relevante en este momento.
                        </>
                      }
                      chart={{
                        data: dataJSON["Idioma que estuda"],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Nivel de estudios"
                      question={
                        <>
                          Selecciona el nivel de estudios que estás cursando o
                          el último que hayas concluido:
                          <br />
                          <br />
                          Hablando del nivel escolar, ¿en qué nivel de educación
                          básica se encuentra actualmente su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON["Escolaridade"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Tipo de instituición"
                      question={
                        <>
                          ¿En qué tipo de institución estudia actualmente? Si no
                          estudias actualmente, ¿en qué tipo de institución
                          estudiaste la mayor parte de tus estudios?
                          <br />
                          <br />
                          ¿En qué tipo de institución estudia actualmente?
                        </>
                      }
                      chart={{
                        data: dataJSON["Tipo de instituição que estuda"],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Formato de clase"
                      question={
                        <>
                          ¿Cuál es tu formato de clase en Wizard?
                          <br />
                          <br />
                          ¿Cuál es el formato de clase de su hijo(a) en Wizard?
                        </>
                      }
                      chart={{
                        data: dataJSON["Formato de aulas"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Modalidad"
                      question={
                        <>
                          ¿Cuál es la modalidad de tus clases en Wizard?
                          <br />
                          <br />
                          ¿Cuál es la modalidad de las clases de su hijo(a) en
                          Wizard?
                        </>
                      }
                      chart={{
                        data: dataJSON["Modalidade"],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Tiempo estudiando en Wizard"
                      question={
                        <>
                          ¿Cuánto tiempo llevas estudiando en Wizard??
                          <br />
                          <br />
                          ¿Cuánto tiempo lleva su hijo(a) estudiando en Wizard?
                        </>
                      }
                      chart={{
                        data: dataJSON["Tempo de Escola"],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Razones por las que elegiste Wizard"
                      question={
                        <>
                          ¿Cuáles fueron las razones por las que elegiste
                          Wizard, en comparación con otras escuelas/marcas que
                          consideraste?
                        </>
                      }
                      chart={{
                        data: dataJSON["Motivos para escolher a Wizard"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Razones por las que elegiste Wizard"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Motivos para escolher a Wizard"
                        ],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Libro/Nivel que esta"
                      question={
                        <>
                          ¿En qué libro/nivel estás?
                          <br />
                          ¿En qué libro/nivel de inglés estás?
                          <br />
                          <br />
                          ¿En qué libro/nivel está su hijo(a)?
                          <br />
                          ¿En qué libro/nivel de inglés su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON["Livro/Nível que está"],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Portada Kids 2"
                      question={
                        <>
                          ¿Cuál de las dos opciones de portada es la de su
                          libro?
                          <br />
                          <br />
                          ¿Cuál de las dos opciones de portada es la del libro
                          que usa actualmente su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON["Capa Kids 2"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Portada Teens 6"
                      question={
                        <>
                          ¿Cuál de las dos opciones de portada es la de su
                          libro?
                          <br />
                          <br />
                          ¿Cuál de las dos opciones de portada es la del libro
                          que usa actualmente su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON["Capa Teens 6"],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Portada W10"
                      question={
                        <>
                          ¿Cuál de las dos opciones de portada es la de su
                          libro?
                          <br />
                          <br />
                          ¿Cuál de las dos opciones de portada es la del libro
                          que usa actualmente su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON["Capa W10"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Tiempo estudiando tu actual libro"
                      question={
                        <>
                          ¿Cuánto tiempo llevas estudiando tu actual libro de
                          Wizard?
                          <br />
                          <br />
                          ¿Cuánto tiempo lleva su hijo(a) estudiando su actual
                          libro de Wizard?
                        </>
                      }
                      chart={{
                        data: dataJSON["Tempo que está no livro"],
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === "#onboarding" && (
              <>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Razón a elegir el formato 100% presencial"
                      question={
                        <>
                          ¿Qué te motivo a elegir el formato de estudio 100%
                          presencial?
                          <br />
                          <br />
                          ¿Qué le motivó a elegir el formato de estudio 100%
                          presencial para su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON["Motivo da escolha 100% presencial"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Razón a elegir el formato 100% presencial"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Motivo da escolha 100% presencial"
                        ],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Razón a elegir el formato 100% en línea"
                      question={
                        <>
                          ¿Qué te motivó a elegir el formato de estudio 100% en
                          línea?
                          <br />
                          <br />
                          ¿Qué le motivó a elegir el formato de estudio 100% en
                          línea para su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON["Motivo da escolha 100% online"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Razón a elegir el formato 100% en línea"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Motivo da escolha 100% online"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATCombo"
                      title="Satisfacción con el material didáctico"
                      question={
                        <>
                          En una escala del 1 al 5, ¿qué tan satisfecho está con
                          el material didáctico que está utilizando en este
                          momento?
                        </>
                      }
                      chart={{
                        data: dataJSON["Satisfação Material Didático (Q1)"],
                        language: "espanhol",
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Que se necesita para mejorar el Material Didáctico"
                      question={
                        <>
                          ¿Qué considera que hace falta para que el material
                          didáctico de Wizard sea mejor?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "O que precisa melhorar Material Didático (Q1)"
                        ],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Que se necesita para mejorar el Material Didáctico"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | O que precisa melhorar Material Didático (Q1)"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Experiencia con las clases"
                      question={
                        <>
                          Ahora queremos saber un poco más acerca de tu
                          experiencia con las clases.
                          <br />
                          Pensando en tu experiencia con las clases, ¿en qué
                          medida estás de acuerdo con las siguientes frases?
                          <br />
                          <br />
                          Ahora queremos saber un poco más acerca de la
                          experiencia de su hijo(a) con las clases.
                          <br />
                          Pensando en la experiencia de su hijo(a), ¿en qué
                          medida está de acuerdo con las siguientes frases?
                        </>
                      }
                      chart={{
                        data: dataJSON["Como melhorar experiência"],
                        language: "espanhol",
                      }}
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Cómo mejorar tu experiencia"
                      question={
                        <>
                          ¿Qué podríamos hacer para mejorar tu experiencia en
                          las clases de Wizard?
                          <br />
                          <br />
                          ¿Qué podríamos hacer para mejorar la experiencia de su
                          hijo(a) en las clases de Wizard?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "Como podemos melhorar a experiência com as aulas da Wizard"
                        ],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Cómo mejorar tu experiencia"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Como podemos melhorar a experiência com as aulas da Wizard"
                        ],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Por qué la experiencia es buena"
                      question={
                        <>
                          ¿Cuáles son las características de Wizard que más
                          contribuyen a tu buena experiencia con las clases?
                          <br />
                          <br />
                          ¿Cuáles son las características de Wizard que más
                          contribuyen a la buena experiencia con las clases de
                          su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "O que mais contribui para uma a boa experiência com as aulas da Wizard"
                        ],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Por qué la experiencia es buena"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | O que mais contribui para uma a boa experiência com as aulas da Wizard"
                        ],
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === "#aprendizado" && (
              <>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Evaluación professor"
                      question={
                        <>
                          Pensando en tu profesor, ¿hasta qué punto estás de
                          acuerdo con las siguientes afirmaciones?
                          <br />
                          <br />
                          Pensando en el profesor de su hijo(a), ¿hasta qué
                          punto está de acuerdo con las siguientes afirmaciones?
                        </>
                      }
                      chart={{
                        data: dataJSON["Avaliação do(a) professor(a)"],
                        language: "espanhol",
                      }}
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Objetivos alcanzados al aprender un idioma"
                      question={
                        <>
                          ¿Qué objetivo te ayuda a alcanzar el aprendizaje de un
                          idioma? Elige sólo uno (el principal)
                          <br />
                          <br />
                          ¿Qué objetivo ayuda a alcanzar a su hijo(a) el
                          aprendizaje de un idioma? Elija sólo una opción (la
                          principal):
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "Objetivos alcançado com aprendizado de um idioma"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Aprendizajes que te gustaría adquirir"
                      question={
                        <>
                          ¿Cuáles son los principales aprendizajes que te
                          gustaría alcanzar al finalizar este curso? (Selecciona
                          máximo 3 opciones)
                          <br />
                          <br />
                          ¿Cuáles son los principales aprendizajes que le
                          gustaría que su hijo(a) alcanzara con este curso?
                          (Selecciona máximo 3 opciones)
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "Aprendizados que gostaria de conquistar"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Cuánto crees que Wizard te está ayudando a alcanzar tus principales objetivos de aprendizaje"
                      question={
                        <>
                          Del 1 al 5 ¿Cuánto crees que Wizard te está ayudando a
                          alcanzar tus principales objetivos de aprendizaje?
                          <br />
                          <br />
                          Del 1 al 5, ¿Cuánto cree que Wizard le está ayudando a
                          su hijo(a) a alcanzar sus principales objetivos de
                          aprendizaje?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "O quanto a Wizard está ajudando a alcançar as conquistas"
                        ],
                        language: "espanhol",
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Que razón tienes para pensar que Wizard no te está ayudando"
                      question={
                        <>
                          ¿Cuál es la razón por la que Wizard no te está
                          ayudando a alcanzar tus objetivos de aprendizaje que
                          mencionaste anteriormente?
                          <br />
                          <br />
                          ¿Cuál es la razón por la que Wizard no está ayudando a
                          su hijo(a) a alcanzar sus principales objetivos de
                          aprendizaje que mencionó anteriormente?
                        </>
                      }
                      chart={{
                        data: dataJSON["Por que não contribui"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Que razón tienes para pensar que Wizard no te está ayudando"
                      chart={{
                        data: dataJSON["WORDCLOUD | Por que não contribui"],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Características que mas te ayudan"
                      question={
                        <>
                          ¿Cuáles son las características de Wizard que más te
                          ayudan a alcanzar tus objetivos de aprendizaje que
                          mencionaste anteriomente?
                          <br />
                          <br />
                          ¿Cuáles son las características de Wizard que más
                          le/la ayudan a alcanzar sus principales objetivos de
                          aprendizaje que mencionó anteriormente?
                        </>
                      }
                      chart={{
                        data: dataJSON["Características que mais contribuem"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Características que mas te ayudan"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Características que mais contribuem"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="TableChart"
                      title="Nombre del professor"
                      question={
                        <>
                          ¿Cuál es el nombre de tu profesor?
                          <br />
                          <br />
                          ¿Cuál es el nombre del profesor de su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON["Nome Professor (Q2)"],
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === "#rematricula" && (
              <>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="PieChart"
                      title="Planeas continuar en Wizard para el próximo semestre"
                      question={
                        <>
                          ¿Tiene previsto continuar con sus clases en Wizard
                          durante el próximo semestre?
                          <br />
                          <br />
                          ¿Tiene previsto que su hijo(a) continúe con sus clases
                          en Wizard durante el próximo semestre?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "Pretende continuar na Wizard no próximo semestre"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Principales razones por el cual deseas continuar en Wizard"
                      question={
                        <>
                          ¿Cuál es el principal motivo por el cual deseas
                          continuar estudiando en Wizard??
                          <br />
                          <br />
                          ¿Cuál es el principal motivo por el cual deseas
                          continuar estudiando en Wizard?
                        </>
                      }
                      chart={{
                        data: dataJSON["Motivo para continuar na Wizard"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="Principales razones por las que no deseas continuar en Wizard"
                      question={
                        <>
                          ¡Qué lástima! ☹ ¿Cuáles son las principales razones
                          por las que no deseas continuar estudiando en
                          Wizard?(seleccione hasta 3 opciones)
                          <br />
                          <br />
                          ¡Qué lástima! ☹ ¿Cuáles son las principales razones
                          por las que no inscribirá nuevamente a su hijo(a) en
                          Wizard? (Selecciona máximo 3 opciones):
                        </>
                      }
                      chart={{
                        data: dataJSON["Motivo para não continuar na Wizard"],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Que podemos hacer para tu continuar con nosotros"
                      question={
                        <>
                          ¿Qué podríamos hacer para continuaras estudiando con
                          nosotros??
                          <br />
                          <br />
                          ¿Qué podríamos hacer para que su hijo(a) continuara
                          estudiando con nosotros?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "O que podemos fazer para continuar conosco"
                        ],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Que podemos hacer para tu continuar con nosotros"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | O que podemos fazer para continuar conosco"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="TableChart"
                      title="Escola de idiomas que irá"
                      question={
                        <>
                          ¿A cuál escuela de idiomas asistirás? (Ej.: un centro
                          de idiomas, profesor privado, curso online, app, etc.)
                          <br />
                          <br />
                          ¿A qué escuela o solución de aprendizaje asistirá su
                          hijo? (Ej.: un centro de idiomas, profesor privado,
                          curso online, app, etc.)
                        </>
                      }
                      chart={{
                        data: dataJSON["Escola de idiomas que irá"],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Qué podemos hacer para ayudarte a aprender mejor"
                      question={
                        <>
                          ¿Hay algo que Wizard podría hacer para que aprendas
                          mejor el idioma?
                          <br />
                          <br />
                          ¿Hay algo que Wizard podría hacer para que su hijo(a)
                          aprenda mejor el idioma?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "O que podemos fazer para você aprender melhor"
                        ],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Qué podemos hacer para ayudarte a aprender mejor"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | O que podemos fazer para você aprender melhor"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Que expectativa no fuerón cumplidas"
                      question={
                        <>
                          ¿Cuáles fueron las expectativas que Wizard no cumplió
                          o las diferencias entre lo que se prometió al inicio
                          del curso y la realidad??
                        </>
                      }
                      chart={{
                        data: dataJSON["Qual expectativa não foi cumprida"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Que expectativa no fuerón cumplidas"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Qual expectativa não foi cumprida"
                        ],
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            {tab === "#objetivos" && (
              <>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Interés por cursos temáticos"
                      question={
                        <>
                          ¿Tendrías interés en tomar cursos temáticos de inglés
                          (por ejemplo, inglés para negocios, inglés para
                          viajes, inglés para conversación) de corta duración?
                          <br />
                          <br />
                          Tendría interés en que su hijo(a) tome cursos
                          temáticos de inglés (por ejemplo, inglés para
                          negocios, inglés para viajes, inglés conversacional)
                          de corta duración?
                        </>
                      }
                      chart={{
                        data: dataJSON["Interesse em cursos temáticos"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="En cúales cursos temáticos estarías interesado"
                      question={
                        <>
                          ¿En cuáles cursos de inglés temáticos estarías
                          interesado?(Selecciona máximo 3 opciones)
                          <br />
                          <br />
                          ¿En cuáles cursos de inglés temáticos estaría
                          interesado(a) que su hijo(a) participara? (Selecciona
                          máximo 3 opciones)
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "Quais cursos temáticos teria interesse"
                        ],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="BarChart"
                      title="Factor determinante para que te inscribas en un curso temático"
                      question={
                        <>
                          ¿Cuál sería un factor determinante para que te
                          inscribas en un curso temático de inglés?
                          <br />
                          <br />
                          ¿En cuáles cursos de inglés temáticos estaría
                          interesado(a) que su hijo(a) participara? (Selecciona
                          máximo 3 opciones)
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "Fator determinante para se matricular num curso temático"
                        ],
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <CardChart
                      type="PieChart"
                      title="Interés por estudiar otro idioma"
                      question={
                        <>
                          ¿Estás interesado(a) en estudiar otro idioma con
                          Wizard?
                          <br />
                          <br />
                          ¿Está interesado(a) en que su hijo(a) estudie otro
                          idioma en Wizard?
                        </>
                      }
                      chart={{
                        data: dataJSON["Interesse em estudar outro idioma"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="BarChart"
                      title="En cuáles idiomas estás interesado"
                      question={
                        <>
                          ¿En cuál(es) idioma(s) estás interesado(a)?
                          <br />
                          <br />
                          ¿En cuál(es) idioma(s) está interesado(a) en que su
                          hijo(a) estudie?
                        </>
                      }
                      chart={{
                        data: dataJSON["Quais idiomas teria interesse"],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Evaluación de la unidad - Presencial"
                      question={
                        <>
                          Pensando en su escuela, ¿cómo evalúa los siguientes
                          elementos?
                          <br />
                          <br />
                          Pensando en la escuela de su hijo(a), ¿cómo evalúa los
                          siguientes elementos?
                        </>
                      }
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                      chart={{
                        data: dataJSON["Avaliação da unidade - Presencial"],
                        language: "espanhol",
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Sugerencia de mejoras para la estructura físicasica"
                      question={
                        <>
                          ¿Tienes alguna sugerencia de mejoras para la
                          estructura física de la escuela donde estudias?
                          <br />
                          <br />
                          ¿Tiene alguna sugerencia de mejora para la estructura
                          física de la escuela donde estudia su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "Sugestão de melhoria para estrutura física"
                        ],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Sugerencia de mejoras para la estructura físicasica"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Sugestão de melhoria para estrutura física"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Evaluación de la unidad - In línea"
                      question={
                        <>
                          ¿Qué tan de acuerdo estás com cada uma de las
                          afirmaciones siguientes?
                        </>
                      }
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                      chart={{
                        data: dataJSON["Avaliação da unidade - Online"],
                        language: "espanhol",
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Sugerencia de mejoras para la escuela"
                      question={
                        <>
                          ¿Tienes alguna sugerencia de mejora con relación a la
                          escuela donde estás inscrito(a)?
                          <br />
                          <br />
                          ¿Tiene alguna sugerencia de mejora con relación a la
                          escuela donde está inscrito su hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON["Sugestão de melhoria para a escola"],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Sugerencia de mejoras para la escuela"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Sugestão de melhoria para a escola"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="CSATLines"
                      title="Evaluación de herramientas"
                      question={
                        <>
                          ¿Qué tan satisfecho estás con las siguientes
                          herramientas considerando su contribución a tu
                          aprendizaje?
                          <br />
                          <br />
                          ¿Qué tan satisfecho(a) está con las siguientes
                          herramientas, considerando su contribución al
                          aprendizaje de su hijo(a)?
                        </>
                      }
                      extra={
                        <>
                          <FaQuestionCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Ajuda"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "csat",
                                })
                              )
                            }
                          />
                        </>
                      }
                      chart={{
                        data: dataJSON["Avaliação das ferramentas"],
                        language: "espanhol",
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Que necessita mejorar en las herramientas Wizard"
                      question={
                        <>
                          ¿Qué consideras que hace falta para que las
                          herramientas de Wizard sean mejores?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "Precisa melhorar Ferramentas da Wizard (Q4)"
                        ],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Que necessita mejorar en las herramientas Wizard"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Precisa melhorar Ferramentas da Wizard (Q4)"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <CardChart
                      type="TableChart"
                      title="Puntos positivos herramientas Wizard"
                      question={
                        <>
                          ¿Qué hace que las herramientas de Wizard sean buenas y
                          contribuyan a tu aprendizaje?
                          <br />
                          <br />
                          ¿Qué hace que las herramientas de Wizard sean buenas y
                          contribuyan al aprendizaje de tu hijo(a)?
                        </>
                      }
                      chart={{
                        data: dataJSON[
                          "Pontos positivos Ferramentas da Wizard"
                        ],
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <CardChart
                      type="WorldCloud"
                      title="WORDCLOUD | Puntos positivos herramientas Wizard"
                      chart={{
                        data: dataJSON[
                          "WORDCLOUD | Pontos positivos Ferramentas da Wizard"
                        ],
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <CardChart
                      type="PieChart"
                      title="Utilizas alguna otra herramienta"
                      question={
                        <>
                          ¿Utilizas otras herramientas además de las
                          mencionadas, para las clases de Wizard?
                          <br />
                          <br />
                          ¿Su hijo(a) utilizas otras herramientas además de las
                          mencionadas, para las clases de Wizard?
                        </>
                      }
                      chart={{
                        data: dataJSON["Utiliza mais alguma ferramenta"],
                      }}
                    />
                  </Col>
                  {/* <Col span={12}>
                    <Card
                      style={{ margin: 10, height: "300px" }}
                      styles={{
              body: { padding: "0px" }
            }}
                      title="Outros - Utiliza mais alguma ferramenta"
                      extra={
                        <>
                          <FaInfoCircle
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            title="Informativo"
                            onClick={() =>
                              dispatch(
                                setInfoModal({
                                  type: "question",
                                  content: (
                                    <>
                                      
                                    </>
                                  ),
                                })
                              )
                            }
                          />
                        </>
                      }
                    >
                      <TableChart
                        data={
                          dataJSON["Outros - Utiliza mais alguma ferramenta (Q4)"]
                        }
                      />
                    </Card>
                  </Col> */}
                </Row>
              </>
            )}
            {tab === "#respondentes" && (
              <>
                <Row>
                  <Col span={24}>
                    <Card
                      style={{ margin: 10 }}
                      styles={{ body: { padding: 5 } }}
                    >
                      <Button
                        value={"Download de Base"}
                        type="primary"
                        size="small"
                        onClick={() => setIsModalExcelOpen(true)}
                      >
                        Download de Base
                      </Button>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CardChart
                      type="TableChart"
                      chart={{
                        data: dataJSON["Respondentes"],
                        design: "respondentes",
                        fullscreen: false,
                      }}
                      style={{ margin: 10 }}
                      styles={{}}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Modal
              title="Download de Base"
              open={isModalExcelOpen}
              onOk={() => {
                setIsModalExcelOpen(false);
                setHasFile("");
              }}
              okText="Fechar"
              closable={false}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              {hasFile === "" && (
                <div id="link-excel" className="process">
                  <RiFileDownloadFill /> Aguarde.. extraindo dados...
                </div>
              )}

              {hasFile !== "" && hasFile !== "error" && (
                <a
                  href={hasFile}
                  target="_blank"
                  rel="noreferrer"
                  id="link-excel"
                >
                  <RiFileExcel2Fill /> Baixar Arquivo
                </a>
              )}

              {hasFile !== "" && hasFile === "error" && (
                <div id="link-excel" className="error">
                  <RiCloseCircleFill /> Falha ao baixar arquivo!
                </div>
              )}
            </Modal>

            <Modal
              title={titleFullScreen}
              open={modalVisible}
              onOk={() => dispatch(setIsModalVisible(false))}
              onCancel={() => {
                dispatch(setIsModalVisible(false));
                dispatch(setChartType(""));
                dispatch(setChartData(null));
                dispatch(setFullScreenTitle(""));
              }}
              footer={null}
              width={window.innerWidth}
            >
              <CreateElement />
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default PearsonWizardEspanhol2024;
