import React, { useContext } from "react";
import { Card, Row, Col } from "antd";

//styles
import "../../assets/css/general/home.css";

//icons
import CXIcon from "../../assets/images/inovyo-customer-experience.png";

//providers
import { Context } from "../../providers/AuthProvider";

const InitPage = () => {
  let { user } = useContext(Context);

  let labels = {
    "pearson-wizard": "Pearson - Wizard",
    "pearson-wizard-espanhol": "Pearson - Wizard (Espanhol)",
    "pearson-yazigi": "Pearson - Yázigi",
    "pearson-wizard-2024": "Pearson - Wizard 2024",
    "pearson-wizard-espanhol-2024": "Pearson - Wizard 2024 (Espanhol)",
  };

  return (
    <div id="home-page">
      <Card title="Dashboards">
        <Row gutter={8}>
          {user.Grupo.length > 0 &&
            user.Grupo.map((group, i) => {
              return (
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  xl={{ span: 6 }}
                  key={"home-item-" + i}
                >
                  <a
                    href={`/dashboardlight/${group}`}
                    className="item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="icon">
                      <img src={CXIcon} alt={"Ícone"} />
                    </div>
                    <div className="description">
                      <strong>{labels[group]}</strong>
                      <br />
                      <span>Custom Experience</span>
                    </div>
                  </a>
                </Col>
              );
            })}

          {user.Grupo.length <= 0 && (
            <Col span={24}>Nenhum dashboard exibido</Col>
          )}
        </Row>
      </Card>
    </div>
  );
};

export default InitPage;
