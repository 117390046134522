import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "dashboard",
  initialState: {
    response: null,
    responseLight: null,
    edition: false,
    menuOpen: true,
    tabActive: "#nps",
    infoModal: null,

    isModalVisible: false,
    chartType: "",
    chartData: null,
    fullScreenTitle: "",
  },
  reducers: {
    setResponse(state, { payload }) {
      state.response = payload;
    },
    setResponseLight(state, { payload }) {
      state.responseLight = payload;
    },
    setInfoModal(state, { payload }) {
      state.infoModal = payload;
    },
    setEdticion(state, { payload }) {
      return { ...state, edition: payload };
    },
    setMenuOpen(state, { payload }) {
      return { ...state, menuOpen: payload };
    },
    setTabActive(state, { payload }) {
      return { ...state, tabActive: payload };
    },

    setIsModalVisible(state, { payload }) {
      return { ...state, isModalVisible: payload };
    },
    setChartType(state, { payload }) {
      return { ...state, chartType: payload };
    },
    setChartData(state, { payload }) {
      return { ...state, chartData: payload };
    },
    setFullScreenTitle(state, { payload }) {
      return { ...state, fullScreenTitle: payload };
    },

  },
});

export const {
  setEdticion,
  setMenuOpen,
  setResponse,
  setTabActive,
  setResponseLight,
  setInfoModal,

  setIsModalVisible,
  setChartType,
  setChartData,
  setFullScreenTitle,
} = slice.actions;

export const response = (state) => state.dashboard.response;
export const responseLight = (state) => state.dashboard.responseLight;
export const edition = (state) => state.dashboard.edition;
export const menuOpen = (state) => state.dashboard.menuOpen;
export const tabActive = (state) => state.dashboard.tabActive;
export const infoModal = (state) => state.dashboard.infoModal;

export const isModalVisible = (state) => state.dashboard.isModalVisible;
export const chartType = (state) => state.dashboard.chartType;
export const chartData = (state) => state.dashboard.chartData;
export const fullScreenTitle = (state) => state.dashboard.fullScreenTitle;

export default slice.reducer;
