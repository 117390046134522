import axios from "axios";
import { getStorage } from "../utils/Storage";

const hostName = window.location.hostname;

const baseURL =
  hostName === "localhost"
    ? "http://localhost:8000/api"
    : "https://account.inovyo.com/api";

const settings = {
  baseURL,
  responseType: "json",
};

const api = axios.create(settings);
export default api;

export const headers = (() => {
  let contentHeader = {};
  const token = getStorage("jwtToken");

  if (token && token !== null && token !== "") {
    contentHeader.Authorization = `Bearer ${token !== "" ? token : ""}`;
  }

  return contentHeader;
})();
