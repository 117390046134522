import React, { useState, useEffect } from "react";
import { Table } from "antd";

const TableChart = (props) => {
  let { data: dataJson, design } = props;
  let { columns, data } = dataJson;

  const [dataTable, setDataTable] = useState(null);
  const [dataColumns, setDataColumns] = useState(null);
  const [tableProps, setTableProps] = useState(null);

  let settings = {
    default: {
      pagination: { pageSize: 10 },
      scroll: { y: 190 },
      size: "small",
      showHeader: false,
    },
    respondentes: {
      pagination: { pageSize: 50 },
      scroll: { y: 500 },
      size: "small",
    },
  };

  useEffect(() => {
    let setDesign = settings[design] ? settings[design] : settings["default"];
    setTableProps(setDesign);
    setDataColumns(columns);
    setDataTable(data);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {dataTable !== null && dataColumns !== null && (
        <Table
          style={{ marginTop: "1px" }}
          {...tableProps}
          columns={dataColumns}
          dataSource={dataTable}
        />
      )}
    </>
  );
};

export default TableChart;
